import React, { useEffect, useMemo, useState } from "react";
import { UserType } from "../../../../Models/User";
import useAuthentication from "../../../../Services/Authentication/useAuthentication";
import translator from "../../../Extra/Translation/Translate";
import HelmetComponent from "../../../../Admin/Layout/HelmetComponent/HelmetComponent";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import CheckNonePriceSvg from "../../../Extra/Svg/CheckNonePriceSvg";
import FidelityService from "../../../../Services/FidelityService/FidelityService";
import SettingService from "../../../../Services/Setting/SettingService";
import FidelityModel from "../../../../Models/Fidelity";
import SubscriptionSvg from "../../../Extra/Svg/SubscriptionSvg";
import CalendarSvg from "../../../Extra/Svg/CalendarSvg";
import { BaseCurrency } from "../../../../Models/BaseCurrency";
import UtilsService, {
    getDateFormat_D_M_YY,
} from "../../../../Services/UtilsService";
import { NewConfig } from "../../../../Admin/Components/Fidelity/FidelityForm";
import StopSubsModal from "./stopSubs/StopSubsModal";

interface FidelityItemsProps {
    props: FidelityModel;
    current_fidelity: FidelityModel | undefined;
    base_currency_system: BaseCurrency;
}

const FidelityItems: React.FC<FidelityItemsProps> = ({
    props,
    current_fidelity,
    base_currency_system,
}) => {
    const config: NewConfig = JSON.parse(props.configuration);
    const { user } = useAuthentication();

    const limit_condition = useMemo<boolean>(() => {
        return !current_fidelity || !base_currency_system || !user
            ? false
            : true;
    }, [base_currency_system, current_fidelity, user]);

    return (
        <div>
            <p>Configuration: {JSON.stringify(config)}</p>
        </div>
    );
};

const ManageSubscription = () => {
    const { translate } = translator();
    const [card, setCard] = useState<FidelityModel | undefined>();
    const { user } = useAuthentication();
    const [isMobile, setIsMobile] = useState(false);
    const isCompany = user?.type === UserType.ENTERPRISE;

    const getLastCard = async () => {
        const res = await FidelityService.getLastLoyalty();
        setCard(res);
    };

    const [baseCurrency, setBaseCurrency] = useState<BaseCurrency | null>(null);
    const [currentLoyalty, setCurrentLoyalty] = useState<
        FidelityModel | undefined
    >();

    const getLoyalty = async () => {
        try {
            const res = await FidelityService.getLastValidateCard();
            setCurrentLoyalty(res);
        } catch (err: any) {
            console.error(err);
        }
    };
    const [dataSubcription, setDataSubcription] = useState<any | undefined>();
    const getActiveSubcription = async () => {
        try {
            const res = await FidelityService.getActiveFidelity();
            setDataSubcription(res);
            setCurrentLoyalty(res?.fidelity);
        } catch (err: any) {}
    };

    const getBaseCurrency = async () => {
        const res = await SettingService.getBaseCurrency();
        setBaseCurrency(res);
    };

    useEffect(() => {
        getBaseCurrency();
        getLastCard();
        // getLoyalty();
        getActiveSubcription();
    }, []);

    const translatedSubscription = useMemo(() => {
        switch (dataSubcription?.type) {
            case "annually":
                return translate("SUBSCRIPTION", "Subs_Annual");
            case "monthly":
                return translate("SUBSCRIPTION", "Subs_Mois");
            case "trimestrial":
                return translate("SUBSCRIPTION2", "trimestrial");
            case "semestriel":
                return translate("SUBSCRIPTION2", "semestriel");
            default:
                return translate("SUBSCRIPTION2", "");
        }
    }, [dataSubcription?.type]);

    return (
        <>
            <HelmetComponent
                title={translate("SUBSCRIPTION", "Subscription")}
                description={translate("SUBSCRIPTION", "Subscription")}
                link="/my-subscription"
                keywords=""
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <div className="intro-y mt-5 md:!mt-5 mb-2 items-center mx-0 px-0">
                <h2 className="mr-auto text-2xl font-medium mx-0 px-0">
                    {translate("SUBSCRIPTION", "Manage_subs")}
                </h2>
            </div>
            <div className="intro-y mt-3">
                <div className="box px-4 mx-0 mb-3 dark:!border-2 dark:!border-slate-700">
                    <div className="md-flex justify-between items-center py-6 md:!py-6 px-2 w-full">
                        <div className="flex items-center w-full sm:h-[50px]">
                            <div className="w-full">
                                <div className="text-center md:!text-left font-bold text-lg">
                                    {translate("SUBSCRIPTION", "Subscription")}
                                    {""}
                                    {currentLoyalty
                                        ? currentLoyalty.name
                                        : translate("SUBSCRIPTION", "Free")}
                                </div>
                                <div className="text-center md:!text-left text-base text-gray-500">
                                    {currentLoyalty
                                        ? card && baseCurrency
                                            ? card.is_text_price === false
                                                ? UtilsService.getConvertedFormatedAmount2(
                                                      currentLoyalty.price,
                                                      baseCurrency,
                                                      user?.base_currency,
                                                      2,
                                                  ).replace(".", ",")
                                                : currentLoyalty.price
                                            : "0 €"
                                        : "0 €"}{" "}
                                    /{translatedSubscription}
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:flex justify-between md:mt text-center">
                            <NavLink to="/cards">
                                <button
                                    type="button"
                                    className="btn btn-perfect w-56"
                                >
                                    {translate("SUBSCRIPTION", "Modif_subs")}
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:hidden max-w-sm mx-auto lg:max-w-none mt-1 pt-1 pb-2 px-0">
                <div className="flex overflow-x-auto scrollbar-none mx-auto px-1 justify-center">
                    <NavLink to="/cards">
                        <button
                            type="button"
                            className="btn btn-perfect actif text-sm text-white rounded-full !border-none hover:border-none"
                        >
                            {translate("SUBSCRIPTION", "Modif_subs")}
                        </button>
                    </NavLink>
                </div>
            </div>

            {/* Second section */}
            <div className="intro-y mt-5 md:!mt-7 mb-2 items-center mx-0 px-0">
                <h3 className="mr-auto text-lg font-medium mx-0 px-0 ml-1">
                    {translate("SUBSCRIPTION", "Details_subs")}
                </h3>
            </div>
            <div className="mt-2 mx-0 px-0"></div>
            <div className="box px-4 mx-0 mb-5 dark:!border-2 dark:!border-slate-700">
                <div className="md-flex justify-between items-center py-0 md:!py-2 px-2 w-full">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            <SubscriptionSvg />
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Bill_subs")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {currentLoyalty
                                    ? card && baseCurrency
                                        ? card.is_text_price === false
                                            ? UtilsService.getConvertedFormatedAmount2(
                                                  currentLoyalty.price,
                                                  baseCurrency,
                                                  user?.base_currency,
                                                  2,
                                              ).replace(".", ",")
                                            : currentLoyalty.price
                                        : "0 €"
                                    : "0 €"}{" "}
                                /{translatedSubscription}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center border-t border-b-dark py-0 md:!py-2 px-2 w-full">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            <CalendarSvg />
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Next_Bill")}
                            </div>
                            <div className="text-sm text-slate-500">
                                26/05/2025
                                {dataSubcription?.echeance
                                    ? getDateFormat_D_M_YY(
                                          dataSubcription?.echeance,
                                      )
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block px-2 w-full mt-10 mb-10">
                <div className="text-sm text-slate-500">
                    {translate("SUBSCRIPTION", "General_Desc")}{" "}
                    <a
                        href="/terms-and-conditions"
                        className="cursor-pointer text-[#0c71c3]"
                    >
                        {translate("SUBSCRIPTION", "General_Desc2")}.
                    </a>
                </div>
            </div>

            <div className="mt-2 mx-0 px-0"></div>
            <div className="box px-4 mx-0 mb-5 dark:!border-2 dark:!border-slate-700">
                <div className="md-flex justify-between items-center py-0 md:!py-2 px-2 w-full">
                    {isMobile ? (
                        <NavLink
                            to=""
                            className="flex items-center w-full sm:h-[50px] h-auto"
                        >
                            <div className="w-6 p-1">
                                <CheckNonePriceSvg />
                            </div>
                            <div className="block pl-8 w-full">
                                <div className="text-sm md:!text-base text-red-600 font-medium">
                                    {translate("SUBSCRIPTION", "Delete_subs")}
                                </div>
                            </div>
                            <div className="w-6 p-1 md:hidden">
                                <ChevronRightIcon className="h-6 items-center mx-auto" />
                            </div>
                        </NavLink>
                    ) : (
                        <div className="flex items-center w-full sm:h-[50px]">
                            <div className="block pl-2 w-full">
                                <div className="text-sm md:!text-base text-red-600 font-medium">
                                    {translate("SUBSCRIPTION", "Delete_subs")}
                                </div>
                            </div>
                            <div className="w-6 p-1 md:hidden">
                                <ChevronRightIcon className="h-6 items-center mx-auto" />
                            </div>
                        </div>
                    )}
                    <div className="md-mt text-center hidden md:block">
                        {/* <NavLink to=""> */}
                        {dataSubcription?.id && (
                            <StopSubsModal id={dataSubcription.id} />
                        )}
                        {/* <button
                                type="button"
                                className="btn !bg-red-600 text-white w-25"
                            >
                                {translate("SUBSCRIPTION", "Stop_subs")}
                            </button> */}
                        {/* </NavLink> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageSubscription;
