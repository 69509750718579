import React from "react";
import { ModalContext } from "../../Extra/Modal/ModalContext";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const ItemCardAccount = ({
    icon,
    title,
    description,
    modalContent,
    clickable = true,
    onClick,
    titleClassName,
}: {
    icon: React.ReactNode;
    title: string;
    description: string;
    descriptionClassName?: string;
    modalContent?: React.ReactNode;
    clickable?: boolean;
    onClick: () => void;
    titleClassName?: string;
}) => {
    const { handleModal } = React.useContext(ModalContext);
    return (
        <>
            <div
                className="box p-1 my-2 relative flex h-[110px] px-4 cursor-pointer dark:!border-2 dark:!border-slate-700 dark:!rounded-lg"
                onClick={() => {
                    onClick();
                }}
            >
                <div className="w-[25%] md:!w-[20%] ">
                    <div className="flex justify-center items-center h-full">
                        {icon}
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex justify-start items-center h-full px-3">
                        <div>
                            <div className="flex">
                                <div
                                    className={`text-sm md:!text-base font-bold ${titleClassName}`}
                                >
                                    {title}
                                </div>
                            </div>
                            <div className="flex">
                                <div
                                    className={`text-xs md:!text-sm text-slate-500 font-normal block w-full h-[35px] ${description}`}
                                >
                                    {description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[20%]">
                    <div className="flex justify-center items-center h-full">
                        <ChevronRightIcon className="h-6 items-center mx-auto" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ItemCardAccount;
