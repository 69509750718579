import React, { useEffect, useState } from "react";
import Transaction, {
    OUT_TRANSACTIONS,
    WalletHistoryStatus,
    WalletHistoryType,
} from "../../Models/Transaction";
import TransactionService from "../../Services/Wallets/TransactionService";
import { DataTableColums } from "../Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import DataTable from "../Extra/DataTable/DataTable";
import { formatAmount } from "../../Services/UtilsService";
import translator from "../Extra/Translation/Translate";
import { NavLink, useParams } from "react-router-dom";
import TransactionResponsiveTable, {
    DataResponsiveTableColums,
} from "../Extra/TableResponsive/TransactionResponsiveTable";
import { useFlyoutContext } from "../../App/Provider/FlyOutProvider";
import { useAssets } from "../../App/Provider/AssetsProvider";

const LastTransactionTable = ({ type }: { type?: WalletHistoryType }) => {
    const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
    const [lastTransactions, setLastTransactions] = useState<Transaction[]>([]);

    const { activeComponent } = useFlyoutContext();
    const { getPrincipalAccount } = useAssets();

    const { id } = useParams();
    const { translate } = translator();
    let wallet: any = id;

    const width = document.documentElement.clientWidth;

    if (!wallet && width <= 578) {
        wallet = getPrincipalAccount()?.id;
    }

    const getLatestTransactions = async () => {
        try {
            const data = await TransactionService.getLatestTransactions(
                wallet,
                type,
                WalletHistoryType.PURCHASE_FIDELITY,
            );

            setLastTransactions(width <= 578 ? data.slice(0, 5) : data);
        } catch (err) {
            console.log("error", err);
        }
    };

    const colums: DataTableColums[] = [
        {
            index: "description",
            title: "TRANSACTION_CUSTOMER.Description",
            content: (row: Transaction) => {
                return row.getTransactionDetails(translate);
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "date",
            title: "TRANSACTION_CUSTOMER.Date",
            order: false,
            sort: () => {
                setDirection(direction === "ASC" ? "DESC" : "ASC");
            },
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = OUT_TRANSACTIONS;

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto" ? 8 : 2,
                )}`;

                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const columnMobile: DataResponsiveTableColums[] = [
        {
            index: "date",
            title: "TRANSACTION_CUSTOMER.Date",
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "description",
            title: "TRANSACTION_CUSTOMER.Description",
            content: (row: Transaction) => {
                return row.getTransactionDetails(translate);
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = OUT_TRANSACTIONS;

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto" ? 8 : 2,
                )}`;
                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    useEffect(() => {
        getLatestTransactions();
    }, [wallet, activeComponent]);

    return (
        <div className="box col-span-12 h-auto lg:h-[655px] overflow-hidden dark:!border-2 dark:!border-slate-700">
            <div className="flex justify-between ... px-5 py-4 border-b border-[#d0d0d0] dark:!border-b dark:!border-slate-700">
                <div>
                    <h1 className="truncate font-semibold">
                        {translate("Dashboard", "Recent_Transactions")}
                    </h1>
                </div>
                <div className="flex">
                    <NavLink
                        to={`/transactions${wallet ? `/${wallet}` : ""} `}
                        className="flex font-semibold"
                    >
                        {" "}
                        <span>{translate("Dashboard", "View_All")}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-chevron-right -mt-2px]"
                        >
                            <path d="m9 18 6-6-6-6" />
                        </svg>
                    </NavLink>
                </div>
            </div>
            <DataTable
                className="table-report table co-hidden"
                columns={colums}
                data={lastTransactions}
                pagination={false}
            />
            <TransactionResponsiveTable
                className="simple-hidden py-5"
                columns={columnMobile}
                onSearchInputChange={() => {
                    console.log("silence is golden");
                }}
                data={lastTransactions}
                handlePerRowsChange={() => {
                    console.log("silence is golden");
                }}
                pageLength={10}
                nbPage={1}
                handlePaginationChange={() => {
                    console.log("silence is golden");
                }}
                exportCSV={false}
                last
            />
        </div>
    );
};

export default LastTransactionTable;
