import { Id, toast, ToastOptions, TypeOptions } from "react-toastify";
export type MessageType = TypeOptions;

function alertMessage() {
    const configuration: ToastOptions = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    };

    const info = (message: string) => {
        return toast.info(message, configuration);
    };

    const error = (message: string) => {
        return toast.error(message, configuration);
    };

    const warning = (message: string) => {
        return toast.warning(message, configuration);
    };

    const success = (message: string) => {
        return toast.success(message, configuration);
    };

    const promise = (
        message: string,
        process: Promise<unknown>,
        successMessage?: string,
        throwError = false,
        translate?: any,
        domain?: string,
    ) => {
        return toast.promise(process, {
            pending: {
                render() {
                    return message;
                },
            },
            success: {
                render({ data }) {
                    if (successMessage) {
                        return successMessage;
                    }
                    if (typeof data === "object" && translate) {
                        if (domain) {
                            return translate(domain, (data as any).code);
                        } else {
                            return translate(
                                "BACKEND_RESPONSE",
                                (data as any).message,
                            );
                        }
                    }
                    return `${data}`;
                },
            },
            error: throwError
                ? undefined
                : {
                      render({ data }) {
                          return `${data}`;
                      },
                  },
        });
    };

    const loading = (message: string) => {
        return toast.loading(message);
    };

    const update = (id: Id, message: string, type: MessageType) => {
        return toast.update(id, {
            render: message,
            isLoading: false,
            type: type,
            delay: 1000,
        });
    };

    return {
        info,
        error,
        warning,
        success,
        promise,
        update,
        loading,
    };
}

const alert_message = alertMessage();

export default alert_message;
