import React, { useEffect, useState } from "react";
import { components } from "react-select";

import Cropper, { Area, Point } from "react-easy-crop";

import Option from "../../Models/Select";
import UtilsService from "../../Services/UtilsService";
import SelectInput from "../../Components/Extra/Input/SelectInput";
import translator from "../../Components/Extra/Translation/Translate";
import CryptoService from "../../Services/Wallets/CryptoService";
import Crypto, {
    CropData,
    IJackpotPaymentPayload,
    OCryptoPots,
    PotsPayload,
} from "../../Models/Crypto";
import { NavLink, useParams } from "react-router-dom";
import JackpotPaymentService from "../../Services/Wallets/JackpotPayment/JackpotPaymentService";
import alert_message from "../../Services/Alert/AlertMessage";
import CopySvg from "../../Components/Extra/Svg/CopySvg";
import useTheme from "../../Services/Hooks/useTheme";
import { AppMode } from "../../globalState/reducers/Theme.reducer";
import SaveBouton from "../../Components/Extra/Bouton/SaveBouton";
import PotsHeader from "../Accueil/Base/pots-header";
import Footer from "../Accueil/Base/footer";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import HelmetComponent from "../../Admin/Layout/HelmetComponent/HelmetComponent";

const bg = "/dist/image/bg-top.webp";

const styleCrypto = {
    width: 25,
    height: 25,
    marginRight: 10,
    borderRadius: "50%",
};

const CustomOption = (props: any) => {
    return (
        <components.Option {...props} className="bg-select-view">
            <OptionImage {...props.data} />
        </components.Option>
    );
};

const OptionImage: React.FC<Option> = (props) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <img src={props.image} style={props.style || {}} />
            {props.label}
        </div>
    );
};

const SingleOption = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <OptionImage {...props.data} />
        </components.SingleValue>
    );
};

const Pots = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { setAppMode } = useTheme();
    const { slug } = useParams();
    const { user } = useAuthentication();
    const [debitOptions, setDebitOptions] = useState<Array<Option>>([]);
    const [userUid, setUserUid] = useState<string>("");
    const [amount, setAmount] = useState<number>(0);

    const [processing, setProcessing] = useState<boolean>(false);
    const [processingPots, setProcessingPots] = useState<boolean>(true);
    const [cryptoPots, setCryptoPots] = useState<OCryptoPots>();

    const map = (item: Crypto): Option => {
        const option = new Option(item.name, item.abbreviation, item.logo);

        option.label = item.name;
        option.value = item.abbreviation;
        option.style = styleCrypto;
        option.image = UtilsService.getPhotoUrl(item.logo);

        return option;
    };

    const getOriginWallets = async () => {
        const get_wallets = await CryptoService.getAllAssetPots();

        const assets: Option[] = get_wallets.map((wallet: Crypto) =>
            map(wallet),
        );

        setDebitOptions(assets);
    };

    const getCryptoPots = async () => {
        const payload: PotsPayload = {
            slug: `${slug}`,
        };

        const asset = await CryptoService.getAssetPots(payload);
        setCryptoPots(asset);
        setZoom(asset.crop_data.zoom);
        setCrop({
            x: Number(asset.crop_data.crop_x),
            y: Number(asset.crop_data.crop_y),
        });
        setProcessingPots(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (slug) {
            const jackpot_payment: IJackpotPaymentPayload = {
                // wallet_abbreviation: selectedOptionDebit.value,
                user_uid: userUid,
                amount: amount,
                jackpot_slug: `${slug}`,
            };
            executePayment(jackpot_payment);
        }
    };

    const executePayment = (
        jackpot_payment_payload: IJackpotPaymentPayload,
    ) => {
        setProcessing(true);
        const jackpot_payment = JackpotPaymentService.jackpotPayment(
            jackpot_payment_payload,
        );
        alert_message
            .promise(
                "Demande de paiement en cours ...",
                jackpot_payment,
                undefined,
                true,
            )
            .then(() => {
                // navigate("/accounts");
            })
            .catch((error) => {
                alert_message.error(
                    translate(
                        "BACKEND_RESULT_CODE",
                        error.code,
                        typeof error.stack === "object" ? error.stack : {},
                    ),
                );
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    /* Crop */
    const [crop, setCrop] = useState<Point>();
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [croppedArea, setCroppedArea] = useState<Area>();

    const onCropComplete = async (
        croppedArea: Area,
        croppedAreaPixels: Area,
    ) => {
        setCroppedArea(croppedArea);
        setCroppedAreaPixels(croppedAreaPixels);

        await updateCropData(croppedAreaPixels);
    };

    const updateCropData = async (croppedAreaPixels: Area) => {
        try {
            if (!slug || !crop) return;
            const x =
                croppedAreaPixels.x > 0
                    ? zoom > 1
                        ? `${croppedAreaPixels.x / 2.5}`
                        : `0`
                    : "0";

            const y =
                croppedAreaPixels.y > 0
                    ? zoom > 1
                        ? `${croppedAreaPixels.y / 2.5}`
                        : `${croppedAreaPixels.y + 40}`
                    : "0";

            const crop_data: CropData = {
                x: x,
                y: y,
                zoom: zoom,
                crop_x: crop.x,
                crop_y: crop.y,
            };
            await CryptoService.updateUserAssetCropData(
                slug,
                crop_data,
                translate,
            );
        } catch (err) {}
    };
    /* Crop */

    useEffect(() => {
        getOriginWallets();
    }, []);

    useEffect(() => {
        getCryptoPots();
    }, [slug]);

    useEffect(() => {
        setAppMode(AppMode.LIGHT);
    }, []);
    const width =
        (Number(cryptoPots?.amount_symbol.amount) * 100) /
        Number(cryptoPots?.objective);

    return (
        <>
            <PotsHeader />
            {/* First section */}
            <section className="text-lg text-[white] font-medium break-words">
                <HelmetComponent
                    title={translate("SEO", "Title_Pots")}
                    description={translate("SEO", "Desc_Pots")}
                    link="/pot/"
                    keywords=""
                    imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                    largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                    addPostfixTitle={true}
                    noIndex={true}
                ></HelmetComponent>
                <div
                    className="z-1 h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat flex justify-center items-center"
                    style={{ backgroundImage: `url(${bg})` }}
                >
                    <h1 className="md:!text-4xl text-2xl font-bold tracking-wide text-center text-white">
                        {translate("POTS_PAGE", "ONLINE_JACKPOT")}
                    </h1>
                </div>

                <div className="w-full pt-[-50px] relative bg-[#F1F5F9] pb-2 px-2">
                    {/* Container img */}

                    <div className="  absolute mt-[-125px] left-1/2  border-2 border-[#0d3f9f] shadow-lg -translate-x-1/2 w-11/12 md:w-[792px] h-full z-10 box-border mx-auto text-center rounded-xl max-h-[380px] md:!h-[380px]">
                        {/* {user &&
                            cryptoPots?.user_name ===
                                `${user.first_name} ${user.last_name}` && (
                                <div className="w-full h-full rounded-xl bg-transparent !bg-contain bg-no-repeat ">
                                    {crop && (
                                        <Cropper
                                            image={`${
                                                cryptoPots?.logo
                                                    ? UtilsService.getPhotoUrl(
                                                          cryptoPots.logo,
                                                      )
                                                    : UtilsService.getPulicImage(
                                                          "/dist/image/Cagnotte.webp",
                                                      )
                                            }`}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={2.08421}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                            objectFit="cover"
                                            showGrid={true}
                                            cropShape={"rect"}
                                        />
                                    )}
                                </div>
                            )} */}

                        <div className="!overflow-hidden w-full h-full rounded-xl bg-transparent !bg-contain bg-no-repeat">
                            {!processingPots && (
                                <img
                                    src={`${
                                        cryptoPots && cryptoPots?.logo
                                            ? UtilsService.getPhotoUrl(
                                                  cryptoPots.logo,
                                              )
                                            : UtilsService.getPulicImage(
                                                  "/dist/image/Cagnotte.webp",
                                              )
                                    }`}
                                    alt="Image croppée"
                                    style={{
                                        width: "792px",
                                        height: "380px",
                                        objectFit: "cover",
                                        objectPosition: `-${
                                            cryptoPots?.crop_data?.x || 0
                                        }px -${
                                            cryptoPots?.crop_data?.y || 0
                                        }px`,
                                        zoom: zoom > 1 ? zoom * 2.5 : zoom,
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    {/* Div overlay */}

                    <div className=" text-black pt-[325px] max-w-[600px] mx-auto w-full grid grid-cols-1 gap-12 mb-5">
                        {/* First div */}
                        <div className=" shadow-lg bg-white rounded-xl w-full mx-auto p-[20px] md:!p-[40px]">
                            <div className=" text-center ">
                                <h1 className=" text-3xl text-black capitalize font-bold">
                                    {cryptoPots?.name}
                                </h1>
                                <p className=" text-sm italic pt-2">
                                    {translate("POTS_PAGE", "CREATED_BY")}{" "}
                                    <span className="text-black font-bold">
                                        {" "}
                                        {cryptoPots?.user_name}
                                    </span>
                                </p>
                            </div>
                            <div className=" text-base text-black font-normal mt-[40px]">
                                {cryptoPots?.jackpot_description}
                            </div>

                            {!!cryptoPots?.show_amount && (
                                <div className="text-center mt-[40px]">
                                    <div className="flex justify-center text-sm mb-2">
                                        <div className="italic text-lg">
                                            {translate(
                                                "POTS_PAGE",
                                                "COLLECTED_AMOUNT",
                                            )}{" "}
                                            :
                                        </div>
                                        <div className="italic text-lg ml-2">
                                            <span>
                                                {Number(
                                                    cryptoPots?.amount_symbol
                                                        .amount,
                                                )}
                                                {
                                                    cryptoPots?.amount_symbol
                                                        .symbol
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!!cryptoPots?.show_objective && (
                                <div className="text-center mt-[40px]">
                                    <div className="flex justify-center text-sm mb-2">
                                        <div className="italic text-lg">
                                            {translate(
                                                "POTS_PAGE",
                                                "OBJECTIVE_AMOUNT",
                                            )}{" "}
                                            :
                                        </div>
                                        <div className="italic text-lg ml-2">
                                            <span>
                                                {Number(cryptoPots?.objective)}
                                                {
                                                    cryptoPots?.amount_symbol
                                                        .symbol
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="relative w-full h-2 bg-slate-200 rounded-lg">
                                        <div
                                            className="absolute inset-0 bg-gradient-to-br from-indigo-900 to-blue-500"
                                            aria-hidden="true"
                                            style={{
                                                width: `${
                                                    width > 100 ? 100 : width
                                                }%`,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Second div  with form*/}
                        <div className=" rounded-xl  p-[20px] md:!p-[40px] shadow-lg bg-white ">
                            <h1 className="pl-2 text-lg font-bold text-black">
                                {translate("POTS_PAGE", "PARTICIPATE")}
                            </h1>
                            <div className=" mt-5 ">
                                <form
                                    onSubmit={(e) => handleSubmit(e)}
                                    className=" border-2  border-gray-300 rounded-xl py-5 pl-3 bg-[#E9F5FD] "
                                >
                                    <div className=" grid p-3 px-5">
                                        <label
                                            htmlFor=""
                                            className="text-base ml-1 pb-1"
                                        >
                                            {translate("POTS_PAGE", "AMOUNT")}
                                        </label>
                                        <div className="  rounded-md border border-gray-300 flex  ">
                                            <input
                                                type="text"
                                                className=" w-full p-2 rounded-md "
                                                value={amount}
                                                onChange={(e) =>
                                                    setAmount(
                                                        Number(e.target.value),
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className=" grid p-3 px-5">
                                        <label
                                            htmlFor=""
                                            className="text-base ml-1 pb-1"
                                        >
                                            {translate(
                                                "POTS_PAGE",
                                                "EMAIL_OR_ID",
                                            )}{" "}
                                        </label>
                                        <div className="  rounded-md border border-gray-300 flex  ">
                                            <input
                                                type="text"
                                                className=" w-full p-2 rounded-md "
                                                value={userUid}
                                                onChange={(e) =>
                                                    setUserUid(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className=" grid p-3 px-5">
                                        <button
                                            type="submit"
                                            className=" btn btn-perfect px-5"
                                            disabled={processing}
                                        >
                                            {translate(
                                                "POTS_PAGE",
                                                "PAY_WITH_INF",
                                            )}
                                        </button>
                                        <a
                                            href="/register"
                                            className="text-center text-sm underline mt-7"
                                        >
                                            {translate(
                                                "HOME_PAGE",
                                                "Create_An_Account",
                                            )}
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* Third div */}
                        <div className="p-[20px] md:!p-[40px] rounded-xl shadow-lg text-left bg-white mb-[100px] ">
                            <h1 className=" pl-2 text-lg font-bold">
                                {translate("POTS_PAGE", "SHARE")}
                            </h1>
                            <p className="text-base flex mt-5">
                                <div
                                    className="!cursor-pointer mr-2 my-auto"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            String(
                                                `${process.env.REACT_APP_PUBLIC_URL}/pots/${slug}` ||
                                                    "",
                                            ),
                                        );
                                        alert_message.success(
                                            translate(
                                                "BACKEND_RESULT_CODE",
                                                "link_Copied",
                                            ),
                                        );
                                    }}
                                >
                                    <CopySvg />
                                </div>
                                <span className="w-full truncate text-left">
                                    {`${process.env.REACT_APP_PUBLIC_URL}/pots/${slug}`}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Div final*/}
            <section className="md:!text-xl text-lg text-[black] font-medium break-words">
                <div className=" h-[250px] min-h-[250px] max-h-[250px] pt-5 bg-white text-center font-bold flex flex-col justify-center">
                    <h1 className=" ">
                        {translate("POTS_PAGE", "PARTENAR_USER", {
                            name: cryptoPots?.user_name || "",
                        })}
                    </h1>
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/register`}
                        className="btn btn-perfect text-base border border-white rounded-lg ifx-bouton p-4 px4 py-3 text-white font-medium my-6 mx-auto"
                    >
                        {translate("HOME_PAGE", "Create_An_Account")}
                    </NavLink>
                </div>
            </section>
        </>
    );
};

export default Pots;
