import React, { useRef } from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import { useState } from "react";
import HelmetComponent from "../../../Admin/Layout/HelmetComponent/HelmetComponent";
import CircleCheckSvg from "../../../Components/Extra/Svg/CircleCheckSvg";
import CircleMinusSvg from "../../../Components/Extra/Svg/CircleMinusSvg";
import CheckPriceSvg from "../../../Components/Extra/Svg/CheckPriceSvg";
import CheckNonePriceSvg from "../../../Components/Extra/Svg/CheckNonePriceSvg";
import CreditCardSvg from "../../../Components/Extra/Svg/CreditCardSvg";
import LockPriceSvg from "../../../Components/Extra/Svg/LockPriceSvg";
import ChevronDownPricesSvg from "../../../Components/Extra/Svg/CircleChevronDownPricesSvg ";
import CircleChevronDownPricesSvg from "../../../Components/Extra/Svg/CircleChevronDownPricesSvg ";
import ChevronsDownPricesSvg from "../../../Components/Extra/Svg/ChevronsDownPricesSvg";
import { NavLink, useParams } from "react-router-dom";
import IndividualPricesTable from "../../../Components/Fidelity/IndividualPriceTable";
import { Swiper as SwiperType } from "swiper";

const bg = "/dist/image/bg-top.webp";

const Prices = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const [active, setActive] = useState<"blue" | "black" | "gold">("blue");
    const swiperRef = useRef<SwiperType | null>(null);
    const handleBusinessClick = () => {
        window.location.href = "/business/prices"; // Redirige vers la page des prix pour les entreprises
    };

    const handleSlideChange = (swiper: SwiperType) => {
        const slideIndex = swiper.activeIndex;
        const states: Array<"blue" | "gold" | "black"> = [
            "blue",
            "gold",
            "black",
        ];
        setActive(states[slideIndex % states.length]);
    };

    const handleButtonClick = (color: "blue" | "gold" | "black") => {
        setActive(color);
        const slideIndex = ["blue", "gold", "black"].indexOf(color);
        if (swiperRef.current) {
            swiperRef.current.slideTo(slideIndex);
        }
    };
    return (
        <>
            <section className=" text-[#333] font-medium break-words ">
                <HelmetComponent
                    title={translate("SEO", "Title_Prices")}
                    description={translate("SEO", "Desc_Prices")}
                    link="/prices"
                    keywords={translate("SEO", "Keys_Prices")}
                    imageCard="/dist/image/Fav-Infinexia-512x512.png"
                    largeTwitterCard="/dist/image/Fav-Infinexia-512x512.png"
                    addPostfixTitle={true}
                    noIndex={false}
                ></HelmetComponent>
                <div
                    className="h-[400px] min-h-[400px] max-h-[400px] bg-cover bg-no-repeat flex flex-col justify-center items-center text-white"
                    style={{ backgroundImage: `url(${bg})` }}
                >
                    <h1 className="pt-[100px] md:!text-3xl text-2xl font-bold tracking-wide text-center">
                        {translate("PRICES_PAGE", "TITLE_2")}
                    </h1>
                    <div className="mt-10 text-black text-sm md:!text-lg px-2 pb-2 md:!pb-0 w-11/12 md:!w-1/4">
                        <div className="w-full md:w-full bg-[#F1F5F9] rounded-xl grid grid-cols-2 gap-1 p-1 items-center mx-auto">
                            <button
                                className="px-[16px] py-[8px] ifx-bouton-banner rounded-lg text-white"
                                disabled
                            >
                                {translate("CARD_TYPE", "PARTICULAR")}
                            </button>
                            <button
                                className="px-[16px] py-[8px] text-black"
                                onClick={handleBusinessClick}
                            >
                                {translate("CARD_TYPE", "BUSINESS")}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <div className="pt-5 sticky block  md:!hidden text-sm top-[50px] ... px-2 z-10">
                    <div className="w-full bg-[#F1F5F9] rounded-xl grid grid-cols-3 gap-1 p-1">
                        <button
                            className={`px-[16px] py-[8px] ${
                                active === "blue"
                                    ? "ifx-bouton-banner rounded-lg text-white"
                                    : "text-black"
                            } `}
                            onClick={() => handleButtonClick("blue")}
                        >
                            Blue
                        </button>
                        <button
                            className={`px-[16px] py-[8px] ${
                                active === "gold"
                                    ? "ifx-bouton-banner rounded-lg text-white"
                                    : "text-black"
                            } `}
                            onClick={() => handleButtonClick("gold")}
                        >
                            Gold
                        </button>
                        <button
                            className={`px-[16px] py-[8px] ${
                                active === "black"
                                    ? "ifx-bouton-banner rounded-lg text-white"
                                    : "text-black"
                            } `}
                            onClick={() => handleButtonClick("black")}
                        >
                            Black
                        </button>
                    </div>
                </div>
                <div className=" bg-white py-[15px] md:!py-[75px]">
                    <div className=" grid  grid-cols-1 lg:!grid-cols-3 w-[90%] max-w-[1280px] mx-auto  py-6 md:text-lg  sm:text-base  grid-flow-row  ">
                        <div
                            className={` pb-[10px] rounded-[10px]  mt-[30px] border !border-[#bebebe] ${
                                active == "blue" ? "" : "hidden md:!block"
                            } `}
                        >
                            <div className=" bg-[#2956BE] text-center flex justify-center w-full py-[10px] px-[15px] rounded-t-[10px]">
                                <h3 className=" text-2xl md:!text-3xl text-white ">
                                    Blue
                                </h3>
                            </div>
                            <div className="block text-center">
                                <div className=" pt-[25px] py-[10px] px-[15px]  ">
                                    <h3 className="text-xl md:!text-2xl font-bold text-[#66666] ">
                                        {translate("PRICES_PAGE", "Free")}
                                    </h3>
                                    <p className="text-lg font-bold text-[#66666] pt-[10px] pb-[5px]">
                                        {translate(
                                            "PRICES_PAGE",
                                            "A_Free_Multi_Currency_Account",
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="pl-text-left text-lg font-bold px-[15px]     ">
                                <p className=" px-[20px] text-left text-base font-normal pb-[5px]">
                                    {" "}
                                    {translate("PRICES_PAGE", "Free_Internal")}
                                </p>
                            </div>
                            <div className="mt-[17px] px-[10px] md:!px-[30px]  block text-center ">
                                <div className=" bg-[#F1F5F9] py-[15px] text-lg font-medium rounded-lg">
                                    <p className=" text-xl md:!text-2xl font-bold pb-[5px]">
                                        Blue +
                                    </p>
                                    <p className="text-base md:!text-lg font-normal pb-[5px]">
                                        {translate(
                                            "PRICES_PAGE",
                                            "More_Optional",
                                        )}
                                    </p>
                                    <p className="text-base md:!text-lg font-bold pb-[15px]">
                                        <CreditCardSvg />
                                        {translate(
                                            "PRICES_PAGE",
                                            "Blue_Bank_Card_And_IBAN",
                                        )}
                                    </p>
                                    <p className=" text-lg text-slate-500">
                                        <span className="text-xl md:!text-2xl font-bold text-slate-800 ">
                                            4,90 €
                                        </span>
                                        /{translate("PRICES_PAGE", "Month")}
                                    </p>
                                    <p className="text-sm font-normal pt-[10px]">
                                        <span className="font-bold text-green-600">
                                            {translate(
                                                "PRICES_PAGE",
                                                "2_Months_Free",
                                            )}
                                        </span>{" "}
                                        {translate("PRICES_PAGE", "By_Opting")}
                                    </p>
                                    <p className="text-sm font-normal pb-[5px]">
                                        {translate(
                                            "PRICES_PAGE",
                                            "For_Annual_Subscription",
                                        )}
                                    </p>
                                </div>
                                <div className="mt-[4px] mb-[0px] tracking-wide text-center">
                                    <NavLink
                                        to={`${
                                            lang ? `/${lang}` : ""
                                        }/register`}
                                        className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                                    >
                                        {translate(
                                            "PRICES_PAGE",
                                            "Try_Infinexia_Blue",
                                        )}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${
                                active == "gold" ? "" : "hidden md:!block"
                            }  pb-[5px] rounded-[10px] mt-[30px] lg:!mt-[7px] border !border-[#bebebe] `}
                        >
                            <div className="bg-[#2956BE] text-center flex flex-col items-center py-[10px] px-[15px] pb-[5px] rounded-t-[10px]">
                                <h3 className=" text-2xl md:!text-3xl text-white">
                                    Gold
                                </h3>
                                <p className=" text-base md:!text-lg text-white font-normal">
                                    {translate(
                                        "PRICES_PAGE",
                                        "The_Most_Popular",
                                    )}
                                </p>
                            </div>
                            <div className="block text-center">
                                <div className=" pt-[25px] text-lg font-medium">
                                    <p className="text-lg text-slate-500">
                                        <span className="text-xl md:!text-2xl font-bold text-slate-800">
                                            9,90 €
                                        </span>
                                        / {translate("PRICES_PAGE", "Month")}
                                    </p>
                                    <p className="text-sm font-normal pt-[10px]">
                                        <span className="font-bold text-green-600">
                                            {translate(
                                                "PRICES_PAGE",
                                                "2_Months_Free",
                                            )}
                                        </span>{" "}
                                        {translate("PRICES_PAGE", "By_Opting")}
                                    </p>
                                    <p className="text-sm font-normal pb-[5px]">
                                        {translate(
                                            "PRICES_PAGE",
                                            "For_Annual_Subscription",
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="pt-[20px] pb-[5px] text-left text-base md:!text-lg font-bold md:!px-[50px]  px-[20px]   ">
                                <CreditCardSvg />
                                {translate(
                                    "PRICES_PAGE",
                                    "Gold_Bank_Card_And_IBAN",
                                )}
                                <br />
                                <p className="text-left text-base font-normal pb-[5px]">
                                    {" "}
                                    <br />
                                    <CircleCheckSvg />
                                    {translate("PRICES_PAGE", "CB_Limits")}
                                    <br />
                                    <br />
                                    <CircleCheckSvg />
                                    {translate("PRICES_PAGE", "Reductions_On")}
                                    <br />
                                    <br />
                                    <CircleCheckSvg />
                                    {translate(
                                        "PRICES_PAGE",
                                        "Discounts_On_Purchases",
                                    )}{" "}
                                    <br />
                                    <br />
                                    <CircleMinusSvg />
                                    {translate(
                                        "PRICES_PAGE",
                                        "Cashback_On",
                                    )}{" "}
                                    <br />
                                    <br />
                                    <CircleMinusSvg />
                                    {translate(
                                        "PRICES_PAGE",
                                        "Cashback_On_CB",
                                    )}{" "}
                                    <br />
                                </p>
                            </div>
                            <div className="mt-[0px] mb-[0px] tracking-wide text-center">
                                <NavLink
                                    to={`${lang ? `/${lang}` : ""}/register`}
                                    className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                                >
                                    {translate(
                                        "PRICES_PAGE",
                                        "Try_Infinexia_Gold",
                                    )}
                                </NavLink>
                            </div>
                        </div>
                        <div
                            className={`  pb-[5px] rounded-[10px]  mt-[30px] border !border-[#bebebe] ${
                                active == "black" ? "" : "hidden md:!block"
                            } `}
                        >
                            <div className=" bg-[#2956BE] text-center flex justify-center w-full py-[10px] px-[15px] rounded-t-[10px]">
                                <h3 className=" text-2xl md:!text-3xl  text-white ">
                                    Black
                                </h3>
                            </div>
                            <div className="block text-center">
                                <div className=" pt-[25px] text-lg font-medium">
                                    <p className=" text-lg text-slate-500">
                                        <span className="text-xl md:!text-2xl font-bold text-slate-800">
                                            14,90 €
                                        </span>
                                        / {translate("PRICES_PAGE", "Month")}
                                    </p>
                                    <p className="text-sm font-normal pt-[10px]">
                                        <span className="font-bold text-green-600">
                                            {translate(
                                                "PRICES_PAGE",
                                                "2_Months_Free",
                                            )}
                                        </span>{" "}
                                        {translate("PRICES_PAGE", "By_Opting")}
                                    </p>
                                    <p className="text-sm font-normal pb-[5px]">
                                        {translate(
                                            "PRICES_PAGE",
                                            "For_Annual_Subscription",
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="pt-[20px] pb-[5px] text-left text-base md:!text-lg font-bold md:!px-[50px]  px-[20px]   ">
                                <CreditCardSvg />
                                {translate(
                                    "PRICES_PAGE",
                                    "Black_Bank_Card_And_IBAN",
                                )}
                                <br />
                                <p className="text-left text-base font-normal pb-[5px]">
                                    {" "}
                                    <br />
                                    <CircleCheckSvg />
                                    {translate(
                                        "PRICES_PAGE",
                                        "CB_Limits_Than_Gold",
                                    )}
                                    <br />
                                    <br />
                                    <CircleCheckSvg />
                                    {translate("PRICES_PAGE", "Reductions_On")}
                                    <br />
                                    <br />
                                    <CircleCheckSvg />
                                    {translate(
                                        "PRICES_PAGE",
                                        "Discounts_On_Purchases",
                                    )}{" "}
                                    <br />
                                    <br />
                                    <CircleCheckSvg />
                                    {translate(
                                        "PRICES_PAGE",
                                        "Cashback_On",
                                    )}{" "}
                                    <br />
                                    <br />
                                    <CircleCheckSvg />
                                    {translate(
                                        "PRICES_PAGE",
                                        "Cashback_On_CB",
                                    )}{" "}
                                    <br />
                                </p>
                            </div>
                            <div className="mt-[0px] mb-[0px] tracking-wide text-center">
                                <NavLink
                                    to={`${lang ? `/${lang}` : ""}/register`}
                                    className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                                >
                                    {translate(
                                        "PRICES_PAGE",
                                        "Try_Infinexia_Black",
                                    )}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[20px] md:!mt-[40px] mb-[0px] text-gray-400 animate-[bounce_4s_ease-in-out_infinite] ">
                        <ChevronsDownPricesSvg />
                    </div>
                </div>
                <div id="compare" className="bg-[#F1F5F9] pt-5">
                    <div className="text-xl text-black -z-40 pb-36 w-full  px-2 md:!px-0 md:!w-[90%] max-w-7xl mx-auto">
                        <div className="text-center pt-[50px] hidden md:!block">
                            <h3 className="md:!text-3xl text-2xl font-bold ">
                                {translate(
                                    "PRICES_PAGE",
                                    "Comparative_Accounts",
                                )}
                            </h3>
                        </div>
                        <div className="w-full">
                            <IndividualPricesTable
                                active={active}
                                handleButtonClick={handleButtonClick}
                                handleSlideChange={handleSlideChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Prices;
