import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import translator from "../../../../Components/Extra/Translation/Translate";
import SwitchTex from "../../../../Components/Extra/Switch/SwitchTex";
import SaveBouton from "../../../../Components/Extra/Bouton/SaveBouton";
import { AssetNetworkConfig } from "../../../../Models/FiatPaymentConfig";
import CryptoService from "../../../../Services/Wallets/CryptoService";
import alert_message from "../../../../Services/Alert/AlertMessage";
import {
    AssetNetwork,
    IDepositWithdrawNetwork,
} from "../../../../Services/DepositAdress/DepositAdress";
import NumberInput from "../../../../Components/Extra/Input/InputNumber";

const ConfigAssetForm = () => {
    const { translate } = translator();
    const navigate = useNavigate();

    const { abbr } = useParams();
    const location = useLocation();
    const { config } = location.state || {};

    const [depositNetwork, setDepositNetwork] = useState<
        AssetNetworkConfig[] | undefined
    >();
    const [withdrawNetwork, setWithdrawNetwork] = useState<
        AssetNetworkConfig[] | undefined
    >();

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!depositNetwork) {
            alert_message.error("Deposit configuration undefined");
            return;
        }
        if (!withdrawNetwork) {
            alert_message.error("Withdraw configuration undefined");
            return;
        }
        const payload: IDepositWithdrawNetwork = {
            deposit: depositNetwork,
            withdraw: withdrawNetwork,
        };
        if (abbr) executeConfig(abbr, payload);
    };

    const executeConfig = (abbr: string, config: IDepositWithdrawNetwork) => {
        const update = CryptoService.createOrUpdateAssetConfig(abbr, config);
        alert_message
            .promise(translate("Admin_Fiat", "Configure_in_Progress"), update)
            .then(() => {
                navigate("/admin/assets/list");
            });
    };

    const getAccountListName = async () => {
        if (abbr) {
            try {
                const res = await CryptoService.getWithrawDepostNetwork(abbr);
                const deposit = res.deposit.map((elt: AssetNetwork) => {
                    const find_conf =
                        config && Array.isArray(config.deposit)
                            ? config.withdraw.find(
                                  (conf: any) => conf.name === elt.name,
                              )
                            : undefined;
                    return {
                        ...elt,
                        status: find_conf ? find_conf.status : false,
                        fee: 0,
                        maximum: 0,
                    };
                });

                const withdraw = res.withdraw.map((elt: AssetNetwork) => {
                    const find_conf =
                        config && Array.isArray(config.withdraw)
                            ? config.withdraw.find(
                                  (conf: any) => conf.name === elt.name,
                              )
                            : undefined;
                    return {
                        ...elt,
                        status: find_conf ? find_conf.status : false,
                        fee: find_conf ? find_conf.fee : 0,
                        external_fee: elt.fee,
                    };
                });

                setDepositNetwork(deposit);
                setWithdrawNetwork(withdraw);
            } catch (err: any) {}
        }
    };

    const handleDepositConfigStatus = (name: string, newStatus: boolean) => {
        if (depositNetwork) {
            const newDepositConfig = depositNetwork.map((item) => {
                if (item.name === name) {
                    return {
                        ...item,
                        status: newStatus,
                    };
                }
                return item;
            });
            setDepositNetwork(newDepositConfig);
        }
    };

    const handleWithdrawConfigStatus = (name: string, newStatus: boolean) => {
        if (withdrawNetwork) {
            const newWithdrawConfig = withdrawNetwork.map((item) => {
                if (item.name === name) {
                    return {
                        ...item,
                        status: newStatus,
                    };
                }
                return item;
            });
            setWithdrawNetwork(newWithdrawConfig);
        }
    };

    const handleWithdrawConfigFee = (name: string, newFee: number) => {
        if (withdrawNetwork) {
            const newWithdrawConfig = withdrawNetwork.map((item) => {
                if (item.name === name) {
                    return {
                        ...item,
                        fee: newFee,
                    };
                }
                return item;
            });
            setWithdrawNetwork(newWithdrawConfig);
        }
    };

    useEffect(() => {
        getAccountListName();
    }, []);
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="box p-5">
                <h2 className="intro-y mt-5 text-lg font-medium bg-white px-3 p-2 rounded">
                    {translate("Crypto", "Deposit")}
                </h2>
                <div className="intro-y flex-1   px-2 lg:border-0 !mt-[-12px] ">
                    <div className="grid grid-cols-12 gap-6 mt-[12px]">
                        <div className="col-span-12">
                            <div className="grid grid-cols-1 md:!grid-cols-5 gap-6 border-b relative py-3 items-center sm:px-1.5">
                                {depositNetwork &&
                                    depositNetwork.length > 0 &&
                                    depositNetwork.map(
                                        (
                                            val: AssetNetworkConfig,
                                            i: number,
                                        ) => (
                                            <div
                                                className="flex flex-row items-center space-x-2"
                                                key={i}
                                            >
                                                <div className="text-bold my-1 md-px">
                                                    {val.name}
                                                </div>
                                                <div className="relative px-5 py-1 bg-black-400 md-mt">
                                                    <SwitchTex
                                                        checked={val.status}
                                                        changeState={() => {
                                                            handleDepositConfigStatus(
                                                                val.name,
                                                                !val.status,
                                                            );
                                                        }}
                                                        switch_on="Enable"
                                                        switch_off="Disable"
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box p-5 mt-5">
                <h2 className="intro-y mt-5 text-lg font-medium bg-white px-3 p-2 rounded">
                    {translate("Crypto", "Withdraw")}
                </h2>

                <div className="intro-y flex-1   px-2 lg:border-0 !mt-[-12px] ">
                    <div className="grid grid-cols-12 gap-6 mt-[12px]">
                        <div className="col-span-12">
                            <div className="grid grid-cols-1 gap-6 border-b relative py-3 items-center sm:px-1.5">
                                {withdrawNetwork &&
                                    withdrawNetwork.length > 0 &&
                                    withdrawNetwork.map(
                                        (
                                            val: AssetNetworkConfig,
                                            i: number,
                                        ) => (
                                            <div
                                                className="grid grid-cols-1 md:!grid-cols-3 gap-6 my-2 items-center space-x-2"
                                                key={i}
                                            >
                                                <div className="grid grid-cols-2 space-x-2">
                                                    <div className="text-bold md-px">
                                                        {val.name}
                                                    </div>
                                                    <SwitchTex
                                                        checked={val.status}
                                                        changeState={() => {
                                                            handleWithdrawConfigStatus(
                                                                val.name,
                                                                !val.status,
                                                            );
                                                        }}
                                                        switch_on="Enable"
                                                        switch_off="Disable"
                                                    />
                                                </div>
                                                <div className="relative px-5 py-1 bg-black-400 md-mt">
                                                    <div className="relative z-0 w-full group">
                                                        <NumberInput
                                                            decimal={6}
                                                            val={val.fee || 0}
                                                            onChange={(
                                                                value: number,
                                                            ) =>
                                                                handleWithdrawConfigFee(
                                                                    val.name,
                                                                    value,
                                                                )
                                                            }
                                                            min={0}
                                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                            required
                                                        />
                                                        <label
                                                            htmlFor="asset-withdrawal-fee"
                                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                        >
                                                            {translate(
                                                                "Admin_Assets",
                                                                "Withdrawal_Fee",
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="relative px-5 py-1 bg-black-400 md-mt">
                                                    <div className="relative z-0 w-full group">
                                                        <NumberInput
                                                            decimal={6}
                                                            val={
                                                                val.external_fee ||
                                                                0
                                                            }
                                                            onChange={(
                                                                value: number,
                                                            ) => console.log()}
                                                            min={0}
                                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                            disabled
                                                        />
                                                        <label
                                                            htmlFor="asset-withdrawal-fee"
                                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                        >
                                                            Frais sur whitebit
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5 text-right">
                <SaveBouton htmlType="submit">
                    {translate("Admin_Fiat", "Save")}
                </SaveBouton>
            </div>
        </form>
    );
};

export default ConfigAssetForm;
