import { CryptoChartData, ICryptoPrices } from "../../Models/Crypto";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const getCryptoPrices = async (
    payload: ICryptoPrices,
): Promise<CryptoChartData[]> => {
    return axios
        .post(`/gateways/crypto/prices`, { ...payload })
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const MarketTrendService = {
    getCryptoPrices,
};

export default MarketTrendService;
