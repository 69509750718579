import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register the required components for the chart
ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
);

const ChartComponent2 = ({ assetData }: { assetData: any }) => {
    const chartData = {
        labels: assetData.labels,
        datasets: [
            {
                label: `${assetData.abbr} Price`,
                data: assetData.data,
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                fill: true,
                tension: 0.3, // To smooth out the lines
                pointRadius: 5,
                pointHoverRadius: 7,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Date",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Price (USD)",
                },
            },
        },
    };

    return (
        <div style={{ position: "relative", height: "400px", width: "100%" }}>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default ChartComponent2;
