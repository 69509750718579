export enum LangType {
    FR = "FR",
    EN = "EN",
    ES = "ES",
    DE = "DE",
    PT = "PT",
    IT = "IT",
    PL = "PL",
    NL = "NL",
    RO = "RO",
    CS = "CS",
    HU = "HU",
    SV = "SV",
    EL = "EL",
    BG = "BG",
    DA = "DA",
    FI = "FI",
    SK = "SK",
    HR = "HR",
    LI = "LI",
    SL = "SL",
    LV = "LV",
    EE = "EE",
}
