import React from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import Config from "./tailwind.config";

export const tailwindConfig = resolveConfig(Config as any) as any;

export const getBreakpointValue = (value: string): number => {
    const screenValue = tailwindConfig.theme.screens[value];
    return +screenValue.slice(0, screenValue.indexOf("px"));
};

export const getBreakpoint = () => {
    let currentBreakpoint;
    let biggestBreakpointValue = 0;
    const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    for (const breakpoint of Object.keys(tailwindConfig.theme.screens)) {
        const breakpointValue = getBreakpointValue(breakpoint);
        if (
            breakpointValue > biggestBreakpointValue &&
            windowWidth >= breakpointValue
        ) {
            biggestBreakpointValue = breakpointValue;
            currentBreakpoint = breakpoint;
        }
    }
    return currentBreakpoint;
};

export const hexToRGB = (h: string): string => {
    let r = 0;
    let g = 0;
    let b = 0;
    if (h.length === 4) {
        r = parseInt(`0x${h[1]}${h[1]}`);
        g = parseInt(`0x${h[2]}${h[2]}`);
        b = parseInt(`0x${h[3]}${h[3]}`);
    } else if (h.length === 7) {
        r = parseInt(`0x${h[1]}${h[2]}`);
        g = parseInt(`0x${h[3]}${h[4]}`);
        b = parseInt(`0x${h[5]}${h[6]}`);
    }
    return `${+r},${+g},${+b}`;
};

export const formatValue = (value: number): string =>
    Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumSignificantDigits: 3,
        notation: "compact",
    }).format(value);

export const formatThousands = (value: number): string =>
    Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 3,
        notation: "compact",
    }).format(value);

export const timestampToDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000); // Convertir le timestamp en millisecondes
    const mois = date.getMonth() + 1;
    const jour = date.getDate();
    const annee = date.getFullYear();

    // Formater la date au format "Mois-jour-année"
    const dateFormatee = `${mois < 10 ? "0" + mois : mois}-${
        jour < 10 ? "0" + jour : jour
    }-${annee}`;

    return dateFormatee;
};
