import { useEffect, useRef, useState } from "react";
import translator from "../Extra/Translation/Translate";
import { useCardContext } from "../../App/Provider/CardProvider";
import UserCard, { USER_CARD_STATUS } from "../../Models/UserCard";
import { formatValue } from "../utils/utils";
import { useAppProvider } from "../../App/Provider/AppProvider";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import AddCardVirtualModal from "./CardVirtual/addCardVirtualModal";
import img_virtual1 from "../../images/Virtual-purple.png";
import img_virtual2 from "../../images/Virtual-silver.png";
import { CARD_TYPE } from "../../Models/OrderCard";

export const OrderVirtualCard = () => {
    const { translate } = translator();

    return (
        <button className="btn-perfect py-2 px-4 text-sm rounded-lg ">
            <span>{translate("WALLET", "ADD_CARD")}</span>
        </button>
    );
};

export const CardHeaderAction = () => {
    const { setCardFilter, cardFilter } = useCardContext();
    const { translate } = translator();
    return (
        <div className="hidden md:block mb-5">
            <ul className="flex flex-wrap -m-1">
                <li className="m-1">
                    <button
                        onClick={() => setCardFilter(null)}
                        className={
                            !cardFilter
                                ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out btn-perfect"
                                : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-transparent text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                        }
                    >
                        {translate("TRANSACTION_FILTER", "ALL")}
                    </button>
                </li>
                <li className="m-1">
                    <button
                        onClick={() => setCardFilter(USER_CARD_STATUS.ACTIVE)}
                        className={
                            cardFilter === USER_CARD_STATUS.ACTIVE
                                ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out btn-perfect"
                                : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-transparent text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                        }
                    >
                        {translate("Cards", "Already_ordered")}
                    </button>
                </li>
                <li className="m-1">
                    <button
                        onClick={() =>
                            setCardFilter(USER_CARD_STATUS.DESACTIVE)
                        }
                        className={
                            cardFilter === USER_CARD_STATUS.DESACTIVE
                                ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out btn-perfect"
                                : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-transparent text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                        }
                    >
                        {translate("WALLET", "Inactive")}
                    </button>
                </li>
            </ul>
            <div className="hidden md:flex justify-end mt-[-38px]">
                <AddCardVirtualModal></AddCardVirtualModal>
            </div>
            <div className="flex justify-end">
                <div className="mt-3">{/* <OrderCard></OrderCard> */}</div>
            </div>
        </div>
    );
};

const CardList = () => {
    const {
        userCards,
        refreshUserCards,
        selectedCard,
        setSelectedCard,
        cardFilter,
        userCards2,
        setCurrentLoyalty,
    } = useCardContext();
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const { translate } = translator();

    const scrollRef = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    useEffect(() => {
        setProcessing(true);
        refreshUserCards().then(() => {
            finalizeProcessing();
        });

        // Check if scrolling is needed
        const checkScroll = () => {
            if (scrollRef.current) {
                const { scrollWidth, clientWidth, scrollLeft } =
                    scrollRef.current;
                setShowLeftArrow(scrollLeft > 0);
                setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
            }
        };

        checkScroll(); // Check on mount
        window.addEventListener("resize", checkScroll); // Check on window resize

        return () => {
            window.removeEventListener("resize", checkScroll);
        };
    }, []);

    console.log({ tooooo: userCards });

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: -250, // Adjust this value based on card width
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: 250, // Adjust this value based on card width
                behavior: "smooth",
            });
        }
    };

    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
        }
    };

    const getCardWidthClass = (totalCards: number): string => {
        const minCardWidth = 60; // w-60 in Tailwind CSS is 15rem which is 240px
        const containerWidth = 100; // Assuming full width is 100%

        if (totalCards === 1) {
            return "w-full";
        }

        const cardWidth = containerWidth / totalCards;
        if (cardWidth < minCardWidth) {
            return `w-60`;
        }

        if (totalCards === 2) {
            return "w-1/2";
        } else if (totalCards === 3) {
            return "w-1/3";
        } else if (totalCards === 4) {
            return "w-1/4";
        } else if (totalCards === 5) {
            return "w-1/5";
        } else if (totalCards === 6) {
            return "w-1/6";
        } else {
            return "w-60";
        }
    };

    const filteredCards = userCards.filter(
        (card) =>
            !cardFilter ||
            card.status === cardFilter ||
            (card.status === USER_CARD_STATUS.PREACTIVE &&
                cardFilter === USER_CARD_STATUS.DESACTIVE),
    );

    const cardWidthClass = getCardWidthClass(filteredCards.length);

    return (
        <div className="relative">
            <div
                className="space-y-2 md:space-y-0 md:flex md:overflow-x-auto scrollbar-none md:space-x-0"
                ref={scrollRef}
                onScroll={handleScroll}
            >
                {filteredCards.map((card: UserCard, key: number) => (
                    <label
                        className={`relative hidden md:block cursor-pointer text-left flex-none ${cardWidthClass}`}
                        key={key}
                    >
                        <input
                            type="radio"
                            name="radio-buttons"
                            className="peer sr-only"
                            defaultChecked={
                                selectedCard &&
                                selectedCard.last_4_digits ===
                                    card.last_4_digits
                                    ? true
                                    : false
                            }
                        />
                        <div className="py-1 px-1 rounded duration-150 ease-in-out">
                            <div
                                className="grid grid-cols-1 col-span-2 items-center gap-x-4 box p-4"
                                onClick={() => {
                                    setSelectedCard(card);
                                }}
                            >
                                {/* Card */}
                                <div className="flex items-center space-x-4">
                                    <img
                                        src={
                                            card.card_type ===
                                            CARD_TYPE.VIRTUAL_CARD
                                                ? img_virtual1
                                                : card.card_type ===
                                                    CARD_TYPE.VIRTUAL_CARD_U
                                                  ? img_virtual2
                                                  : card.logo
                                        }
                                        className="rounded h-8 w-[45px] sm:w-[45px]"
                                    ></img>
                                    <div>
                                        <div className="text-sm font-medium text-slate-800 dark:text-slate-100 capitalize">
                                            {card.card_type ===
                                            CARD_TYPE.VIRTUAL_CARD
                                                ? translate(
                                                      "WALLET",
                                                      "VIRTUAL1",
                                                  )
                                                : card.card_type ===
                                                    CARD_TYPE.VIRTUAL_CARD_U
                                                  ? translate(
                                                        "WALLET",
                                                        "VIRTUAL2",
                                                    )
                                                  : card.card_type}
                                        </div>
                                        <div className="text-xs">
                                            **{card.last_4_digits}
                                        </div>
                                    </div>
                                </div>
                                {/* Card status */}
                                <div className="absolute right-0 mr-4">
                                    <div className="text-xs inline-flex font-medium bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400 rounded-full text-center px-2.5 py-1 capitalize">
                                        {/* {translate("Card", card.status)} */}
                                        {!!card.order?.is_active
                                            ? translate(
                                                  "Cards",
                                                  "Already_ordered",
                                              )
                                            : translate("WALLET", "Inactive")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="absolute inset-0 rounded pointer-events-none"
                            aria-hidden="true"
                        />
                    </label>
                ))}
            </div>
            {showLeftArrow && (
                <button
                    className="hidden md:flex absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-full p-2"
                    onClick={scrollLeft}
                >
                    <ChevronLeftIcon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                </button>
            )}
            {showRightArrow && (
                <button
                    className="hidden md:flex absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-full p-2"
                    onClick={scrollRight}
                >
                    <ChevronRightIcon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                </button>
            )}
            <div className="md:hidden space-y-2">
                {userCards.length > 0 &&
                    userCards.map((card: UserCard, key: number) => {
                        return (
                            <>
                                {(!cardFilter ||
                                    card.status === cardFilter ||
                                    (card.status ===
                                        USER_CARD_STATUS.PREACTIVE &&
                                        cardFilter ===
                                            USER_CARD_STATUS.DESACTIVE)) && (
                                    <div
                                        className={`${
                                            selectedCard && "hidden"
                                        } box p-1 my-2 relative flex md:hidden h-auto py-6 px-4 cursor-pointer border dark:!border-2 dark:!border-slate-700`}
                                        onClick={() => setSelectedCard(card)}
                                        key={key}
                                    >
                                        <div className="w-full flex items-center h-full">
                                            <div className="">
                                                <div className="col-span-6 order-1 sm:order-none sm:col-span-3 flex items-center space-x-4 lg:col-span-3">
                                                    <img
                                                        src={
                                                            card.card_type ===
                                                            CARD_TYPE.VIRTUAL_CARD
                                                                ? img_virtual1
                                                                : card.card_type ===
                                                                    CARD_TYPE.VIRTUAL_CARD_U
                                                                  ? img_virtual2
                                                                  : card.logo
                                                        }
                                                        className="rounded h-8 w-[45px]"
                                                    ></img>
                                                    <div>
                                                        <div className="text-sm font-medium text-slate-800 dark:text-slate-100 capitalize">
                                                            {card.card_type ===
                                                            CARD_TYPE.VIRTUAL_CARD
                                                                ? translate(
                                                                      "WALLET",
                                                                      "VIRTUAL1",
                                                                  )
                                                                : card.card_type ===
                                                                    CARD_TYPE.VIRTUAL_CARD_U
                                                                  ? translate(
                                                                        "WALLET",
                                                                        "VIRTUAL2",
                                                                    )
                                                                  : card.card_type}
                                                        </div>
                                                        <div className="text-xs">
                                                            **
                                                            {card.last_4_digits}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="flex justify-start items-center h-full px-3">
                                                <div className="">
                                                    <div className="col-span-6 order-2 sm:order-none sm:col-span-2 text-right lg:col-span-3">
                                                        <div className="text-xs inline-flex font-medium bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400 rounded-full text-center px-2.5 py-1 capitalize">
                                                            {translate(
                                                                "Card",
                                                                card.status,
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-[20%]">
                                            <div className="flex justify-center items-center h-full">
                                                <ChevronRightIcon className="h-6 items-center mx-auto" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        );
                    })}
            </div>
        </div>
    );
};

export default CardList;
