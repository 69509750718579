import "animate.css";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { LangType } from "../../../Models/Lang";
import useLanguage from "../../../Services/Hooks/useLanguage";
import NoticeService from "../../../Services/Notice/NoticeService";
import ParentService, {
    ParentRequest,
} from "../../../Services/User/ParentService";
import { ModalProvider } from "../Modal/ModalContext";
import CloseSimpleSvg from "../Svg/CloseSimpleSvg";
import translator from "../Translation/Translate";
import InfoViewModal from "./InfoNoticeModal";
import ParentInvitation from "./Parentrequest";
import JoinAccountRequestService from "../../../Services/JoinAccountRequestService/JoinAccountRequestService";
import { OUserJoinAccountRequest } from "../../../Models/JoinAccountRequest";
import JoinRequest from "./JoinRequest";
import JackpotPaymentService from "../../../Services/Wallets/JackpotPayment/JackpotPaymentService";
import { OJackpotPayment } from "../../../Models/Crypto";
import JackpotPayment from "./JackpotPayment";

const NotificationInformation: React.FC = () => {
    const { translate } = translator();
    const [show, setShow] = useState<boolean>(false);
    const [isClick, setIsClick] = useState<boolean>(false);
    const [showRedCircle, setShowRedCircle] = useState<boolean>(false);
    const [requests, setRequests] = useState<Array<ParentRequest>>([]);
    const [userInformations, setUserInformations] = useState<any>([]);
    const { language } = useLanguage();
    const [joinRequests, setJoinRequests] =
        useState<OUserJoinAccountRequest | null>(null);
    const [jackpotPayment, setJackpotPayment] = useState<OJackpotPayment[]>([]);

    const lang = language;

    const getAll = async () => {
        try {
            const user_informations = await NoticeService.getAllByUser();
            setUserInformations(user_informations);
            const not_seen = user_informations.filter(
                (elt: any) => elt.userInfo === false,
            );
            if (not_seen.length > 0) {
                setShowRedCircle(true);
            } else {
                setShowRedCircle(false);
            }
        } catch (err: any) {}
    };

    const getAllInvitation = async () => {
        try {
            const parents_request = await ParentService.getAllRequest();
            setRequests(parents_request);
        } catch (err: any) {}
    };

    const getJoin = async () => {
        try {
            const join_request =
                await JoinAccountRequestService.getUserJoinRequest();
            setJoinRequests(join_request);
        } catch (err: any) {}
    };

    const getJackpotPayment = async () => {
        try {
            const jackpot_payment =
                await JackpotPaymentService.getUserJackpotPayment();
            setJackpotPayment(jackpot_payment);
        } catch (err: any) {}
    };

    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        getAll();
        getAllInvitation();
        getJoin();
        getJackpotPayment();
    }, [isClick]);

    const tooltipRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target as Node)
            ) {
                setShow(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    useEffect(() => {
        const doc = document.getElementById("mobile-header");
        if (show) {
            if (doc && !doc.classList.contains("scrolling2")) {
                doc.classList.add("scrolling2");
                doc.classList.add("via-flyout");
            }
        } else {
            if (doc && doc.classList.contains("via-flyout")) {
                doc.classList.remove("scrolling2");
                doc.classList.remove("via-flyout");
            }
        }
    }, [show]);

    return (
        <div ref={tooltipRef}>
            <button
                type="button"
                className="bg-transparent relative border-none use-tooltip-notifinfo"
                onClick={() => {
                    setShow(!show);
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-mail text-white mt-1.5"
                >
                    <rect width="20" height="16" x="2" y="4" rx="2" />
                    <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                </svg>
                {(showRedCircle === true ||
                    requests.length > 0 ||
                    jackpotPayment.length > 0) && (
                    <div className="absolute inline-flex items-center justify-center w-3 h-3 p-1 text-white bg-red-500 rounded-full top-[-0.25px] right-[8px]"></div>
                )}
            </button>
            <ModalProvider>
                <div className={` ${show ? "" : "!hidden"}`}>
                    <Tooltip
                        anchorSelect=".use-tooltip-notifinfo"
                        place="bottom"
                        events={["click"]}
                        isOpen={show}
                        className={`h-[88vh] w-notif-menu  bg-content-notif p-0 !opacity-100 !top-[50px] shadow-lg right-[400px] relative animate__animated animate__slideInRight !pointer-events-auto`}
                        style={{
                            top: "50px",
                            padding: "0px 0px",
                        }}
                        opacity={1}
                    >
                        <div className="w-full p-2">
                            <div className="flex justify-between pb-4">
                                <div>
                                    <h2 className="text-lg p-2 text-bold text-notif">
                                        {translate("Account_Notice", "Notice")}
                                    </h2>
                                </div>
                                <button
                                    type="button"
                                    className="!bg-red-500 border-none absolute top-5 right-5 !rounded-lg md-right-notif "
                                    onClick={() => {
                                        setShow(false);
                                    }}
                                >
                                    <CloseSimpleSvg />
                                </button>
                            </div>
                            <div className="flex justify-end mr-2">
                                <button
                                    type="button"
                                    className="btn btn-perfect"
                                    onClick={async () => {
                                        await NoticeService.MarkAllAsRead();
                                        getAll();
                                    }}
                                >
                                    {translate("Notification", "Mark_ALL_Seen")}
                                </button>
                            </div>
                            <div className="p-2 w-full">
                                {jackpotPayment &&
                                    jackpotPayment.map(
                                        (jackpotPayment: any, key: number) => (
                                            <JackpotPayment
                                                key={key}
                                                jackpotPayment={jackpotPayment}
                                                refresh={getJackpotPayment}
                                            />
                                        ),
                                    )}
                                {requests.map((request: any, key: number) => (
                                    <ParentInvitation
                                        key={key}
                                        request={request}
                                        refresh={getAllInvitation}
                                    />
                                ))}
                                {userInformations.map(
                                    (elt: any, key: number) => (
                                        <InfoViewModal
                                            item={elt}
                                            lang={lang}
                                            refresh={getAll}
                                            key={key}
                                        />
                                    ),
                                )}
                                {joinRequests && (
                                    <JoinRequest
                                        request={joinRequests}
                                        refresh={getJoin}
                                    />
                                )}
                            </div>
                        </div>
                    </Tooltip>
                </div>
            </ModalProvider>
        </div>
    );
};

export default NotificationInformation;
