import React from "react";
import alert_message from "../../../Services/Alert/AlertMessage";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";
import ParentService, {
    ParentRequest,
    ParentsStatus,
} from "../../../Services/User/ParentService";
import { ModalContext } from "../../Extra/Modal/ModalContext";
import translator from "../../Extra/Translation/Translate";
import MessageSvg from "../Svg/MessageSvg";

const RequestContent = ({
    request,
    refresh,
}: {
    request: ParentRequest;
    refresh: () => void;
}) => {
    const { translate } = translator();
    const { getInformationAsync } = AppAuthentication();
    const { handleModal } = React.useContext(ModalContext);

    const changeStatus = (status: ParentsStatus) => {
        try {
            const execute = ParentService.updateStatus(status, request.id);
            alert_message.promise("...", execute).then(() => {
                handleModal();
                getInformationAsync(true);
                refresh();
            });
        } catch {}
    };
    const style = {
        fontFamily: " arial, helvetica, sans-serif",
        fontSize: "12pt",
    };

    return (
        <>
            <div className="text-lg text-center uppercase">
                {translate("PARENT_REQUEST", "TITLE")}
            </div>

            <div className="p-5 block mt-4 mb-2.5">
                <div className="text-justify">
                    <p>
                        <span style={style}>
                            {translate("PARENT_REQUEST", "DESCRIPTION", {
                                first_name_parent: request.partenar.first_name,
                                last_name_parent: request.partenar.last_name,
                                first_name_child: request.child.first_name,
                                last_name_child: request.child.last_name,
                            })}
                        </span>
                    </p>
                </div>
            </div>
            <div className="mt-2 flex gap-2 justify-end w-full">
                <button
                    className="btn btn-perfect"
                    onClick={() => changeStatus(ParentsStatus.ACCEPTED)}
                >
                    {translate("PARENT_REQUEST", "ACCEPT")}
                </button>
                <button
                    className="btn bg-red-500 hover:bg-red-400 text-white"
                    onClick={() => changeStatus(ParentsStatus.DECLINED)}
                >
                    {translate("PARENT_REQUEST", "DECLINE")}
                </button>
            </div>
        </>
    );
};

const ParentInvitation = ({
    request,
    refresh,
}: {
    request: ParentRequest;
    refresh: () => void;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <div
            className={`${"bg-not-view p-2 rounded-b-lg rounded-r-lg cursor-pointer mb-2"}`}
            role="button"
            onClick={async () => {
                handleModal(
                    <RequestContent request={request} refresh={refresh} />,
                );
            }}
            title={translate("Notification", "See")}
        >
            <div className="flex justify-between">
                <div>
                    <div className="inline-flex text-xs font-medium items-center">
                        <MessageSvg /> Invitation
                    </div>
                    <div className={"block truncate mt-2 text-xs text-left"}>
                        {translate("PARENT_REQUEST", "SHORT_DESCRIPTION", {
                            first_name: request.partenar.first_name,
                            last_name: request.partenar.last_name,
                        })}
                    </div>
                </div>
                <div className="text-xs self-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-eye"
                    >
                        <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default ParentInvitation;
