import UtilsService from "../Services/UtilsService";
import { UserCryptoWallet } from "./UserWallet";

export default class Transaction {
    id?: number;
    description: string;
    amount: number;
    usd_price: number;
    status: string;
    date: string;
    type!: WalletHistoryType;
    user_wallet!: UserCryptoWallet;
    partenar?: any;
    user?: any;
    fee?: number;
    target?: UserCryptoWallet;
    date_fr?: string;
    constructor(
        description: string,
        amount: number,
        usd_price: number,
        status: string,
        date: string,
        user: any,
    ) {
        this.description = description;
        this.amount = amount;
        this.status = status;
        this.date = date;
        this.user = user;
        this.usd_price = usd_price;
    }

    getAccountName() {
        return this.user_wallet.is_personal
            ? this.user_wallet.name
            : this.user_wallet.abbreviation;
    }

    getAccountOutName() {
        return this.target?.is_personal
            ? this.target?.name
            : this.target?.abbreviation;
    }

    getCurrency(wallet: any) {
        return wallet && wallet.is_personal
            ? wallet?.currency
            : wallet?.abbreviation;
    }

    formatAmountWithCurrency(wallet: any, amount: number, after?: number) {
        return `${UtilsService.formatAmountV2(
            amount,
            after ? after : wallet.type === "fiat" ? 2 : 8,
        )} ${this.getCurrency(wallet)}`;
    }

    getSwapinAmount() {
        if (this.description.includes("vers ")) {
            const amount = this.description.split("vers ")[1].split(" ")[0];
            return amount;
        }
        if (this.description.includes("en échange de")) {
            const amount = this.description
                .split("en échange de ")[1]
                .split(" ")[0];
            return amount;
        }
        return "";
    }

    getLevel() {
        if (this.description.includes("niveau")) {
            const level = this.description.split("niveau ")[1].split(" ")[0];
            return level;
        }

        return "";
    }

    getJackpot() {
        if (this.description.includes("pour la Cagnotte ")) {
            const level = this.description.split("pour la Cagnotte ")[1];
            return level;
        }

        return "";
    }

    getRef() {
        if (this.description.includes("##")) {
            const level = this.description.split("##")[1].replace(")", "");
            return `${level}`;
        } else if (this.description.includes("#")) {
            const level = this.description.split("#")[1];
            return level.replace(")", "");
        } else if (this.description.includes("Ref : ")) {
            const level = this.description.split("Ref : ")[1].replace(")", "");
            return level.replace(")", "");
        }

        return "";
    }

    getPartner() {
        if (this.description.includes("par ")) {
            try {
                const partner = this.description.split("par ")[1].split(" ")[0];
                return partner;
            } catch (err) {}
        }

        if (
            this.description.includes("de INF") &&
            this.description.includes("ception")
        ) {
            try {
                const partner = this.description
                    .split("de INF")[1]
                    .split(" ")[0];
                return `INF${partner}`;
            } catch (err) {}
        }

        if (this.partenar) {
            return this.partenar.uid;
        }

        return "";
    }

    getTransactionDetails = (
        transate?: (text: string, text2: string, params?: any) => string,
    ): string => {
        if (!transate) return this.description;
        let translated = "";
        let detail = "";

        if (this.description.includes("Achat de")) {
            translated = transate("TRANSACTION_DETAIL", "BUY_DETAIL", {
                amount_currency: `${this.formatAmountWithCurrency(
                    this.user_wallet,
                    this.amount,
                )}`,
            });
            return translated === "BUY_DETAIL" ? this.description : translated;
        }

        if (
            this.description.includes("Commission d'affiliaton niveau") &&
            this.description.includes("sur l'achat effectué par")
        ) {
            translated = transate(
                "TRANSACTION_DETAIL",
                "BUY_COMMISION_LEVEL_DETAIL",
                {
                    amount_currency: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                    level: this.getLevel(),
                    user: this.getPartner(),
                },
            );
            return translated === "BUY_DETAIL" ? this.description : translated;
        }

        switch (this.type) {
            case WalletHistoryType.DEPOSIT:
            case WalletHistoryType.DEPOSIT_VISA:
            case WalletHistoryType.DEPOSIT_KALYPAY:
            case WalletHistoryType.DEPOSIT_CRYPTO:
            case WalletHistoryType.DEPOSIT_VIREMENT:
            case WalletHistoryType.DEPOSIT_PAYEER:
                translated = transate("TRANSACTION_DETAIL", "DEPOSIT_DETAIL", {
                    amount_currency_in: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                });
                return translated === "DEPOSIT_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.STAKING:
                translated = transate("TRANSACTION_DETAIL", "SAVING_DETAIL", {
                    amount_currency: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                });
                return translated === "SAVING_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.DAILY_ROI:
                translated = transate("TRANSACTION_DETAIL", "ROI_DETAIL", {
                    amount_currency: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                        8,
                    )}`,
                });
                return translated === "ROI_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.UNSTAKING:
                translated = transate("TRANSACTION_DETAIL", "UNSAVING_DETAIL", {
                    amount_currency: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                });
                return translated === "UNSAVING_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.STOP_STAKING:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "STOP_SAVING_DETAIL",
                    {
                        amount_currency: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        )}`,
                    },
                );
                return translated === "STOP_SAVING_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.WITHDRAW_MANUAL:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "WITHDRAW_MANUAL_DETAIL",
                    {
                        amount_currency: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        )}`,
                    },
                );
                return translated === "WITHDRAW_MANUAL_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.WITHDRAW_CRYPTO:
            case WalletHistoryType.WITHDRAW_KALYPAY:
            case WalletHistoryType.WITHDRAW_VIREMENT:
            case WalletHistoryType.WITHDRAW_PAYEER:
                translated = transate("TRANSACTION_DETAIL", "WITHDRAW_DETAIL", {
                    amount_currency: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                });
                return translated === "WITHDRAW_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.WITHDRAW:
                translated = transate("TRANSACTION_DETAIL", "WITHDRAW", {
                    amount_currency_in: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                });
                return translated === "WITHDRAW"
                    ? this.description
                    : translated;
            case WalletHistoryType.GAIN_A_TERM:
                translated = transate("TRANSACTION_DETAIL", "GAIN_A_TERM", {
                    amount_currency: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                });
                return translated === "GAIN_A_TERM"
                    ? this.description
                    : translated;
            case WalletHistoryType.COMMISSION_SWAP:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "SWAP_COMMISION_DETAIL",
                    {
                        partner: this.getPartner(),
                    },
                );
                return translated === "SWAP_COMMISION_DETAIL"
                    ? this.description
                    : translated;

            case WalletHistoryType.COMMISSION_PURCHASE:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "BUY_COMMISION_DETAIL",
                    {
                        partner: this.getPartner(),
                    },
                );
                return translated === "BUY_COMMISION_DETAIL"
                    ? this.description
                    : translated;

            case WalletHistoryType.COMMISSION_WITHDRAW:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "WITHDRAW_COMMISION_DETAIL",
                    {
                        partner: this.getPartner(),
                    },
                );
                return translated === "WITHDRAW_COMMISION_DETAIL"
                    ? this.description
                    : translated;

            case WalletHistoryType.COMMISSION_SERVICE_CHARGE:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "SERVICE_CHARGE_COMMISION_DETAIL",
                    {
                        partner: this.getPartner(),
                    },
                );
                return translated === "SERVICE_CHARGE_COMMISION_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.COMMISSION_LOYALTY:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "LOYALTY_COMMISION_DETAIL_V1",
                    {
                        partner: this.getPartner(),
                        user: this.getPartner(),
                    },
                );
                return translated === "LOYALTY_COMMISION_DETAIL_V1"
                    ? this.description
                    : translated;
            case WalletHistoryType.TRANSFER_IN:
            case WalletHistoryType.TRANSFER_IN_V2:
            case WalletHistoryType.TRANSFER_IN_V3:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "TRANSFERT_USER_TO_USER_IN",
                    {
                        user: this.getPartner(),
                        amount_currency_in: this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        ),
                    },
                );
                return translated === "TRANSFERT_USER_TO_USER_IN"
                    ? this.description
                    : translated;
            case WalletHistoryType.TRANSFERT_OUT:
            case WalletHistoryType.TRANSFERT_OUT_V2:
            case WalletHistoryType.TRANSFERT_OUT_V3:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "TRANSFERT_USER_TO_USER_OUT",
                    {
                        user: this.getPartner(),
                        amount_currency_in: this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        ),
                    },
                );
                return translated === "TRANSFERT_USER_TO_USER_OUT"
                    ? this.description
                    : translated;
            case WalletHistoryType.SWAPIN:
                detail = !this.user_wallet.is_personal
                    ? "SWAP_IN_DETAIL"
                    : "SWAP_IN_DETAIL_PERSONAL";

                translated = transate("TRANSACTION_DETAIL", detail, {
                    amount_currency_in: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                    account_name: `${this.getAccountName()}`,
                    amount_currency_out: `${this.getSwapinAmount()} ${this.getCurrency(
                        this.target,
                    )}`,
                });
                return translated === detail ? this.description : translated;
            case WalletHistoryType.SWAPOUT:
                translated = transate("TRANSACTION_DETAIL", "SWAP_OUT_DETAIL", {
                    amount_currency_out: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                    account_name: `${this.getAccountName()}`,
                    amount_currency_in: `${this.getSwapinAmount()} ${this.getCurrency(
                        this.target,
                    )}`,
                });
                return translated === "SWAP_OUT_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.JACKPOT_PAYOUT:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "JACKPOT_PAYOUT_DETAIL",
                    {
                        amount_currency: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        )}`,
                        account_name: this.getJackpot(),
                    },
                );
                return translated === "JACKPOT_PAYOUT_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.PAYMENT:
                translated = transate("TRANSACTION_DETAIL", "PAYMENT_DETAIL", {
                    amount_currency: `${this.formatAmountWithCurrency(
                        this.user_wallet,
                        this.amount,
                    )}`,
                    partner: this.partenar?.uid || "",
                    ref: this.getRef(),
                    merchant_name:
                        this.partenar?.enterprise ?? (this.partenar?.uid || ""),
                });
                return translated === "PAYMENT_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.PAYMENT_RECEPTION:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "PAYMENT_RECEPTION_DETAIL",
                    {
                        amount_currency: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        )}`,
                        amount_currency_fee: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.fee || 0,
                        )}`,
                        partner: this.partenar?.uid || "",
                        ref: this.getRef(),
                    },
                );
                return translated === "PAYMENT_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.JACKPOT_PAYIN:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "JACKPOT_PAYMENT_RECEPTION_DETAIL",
                    {
                        amount_currency: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        )}`,
                        account_name: `${this.getAccountName()}`,
                        partner: `${this.getPartner()}`,
                    },
                );
                return translated === "JACKPOT_PAYMENT_RECEPTION_DETAIL"
                    ? this.description
                    : translated;
            case WalletHistoryType.TRANSFER_ACCOUNT_TO_ACCOUNT_RECEPTION:
            case WalletHistoryType.TRANSFER_ACCOUNT_TO_ACCOUNT_v2_RECEPTION:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "TRANSFERT_ACCOUNT_TO_ACCOUNT_IN",
                    {
                        amount_currency_in: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        )}`,
                        account_name: `${this.getAccountOutName()}`,
                        amount_currency_out: `${this.formatAmountWithCurrency(
                            this.target,
                            this.amount,
                        )}`,
                    },
                );
                return translated === "TRANSFERT_ACCOUNT_TO_ACCOUNT_IN"
                    ? this.description
                    : translated;

            case WalletHistoryType.TRANSFER_ACCOUNT_TO_ACCOUNT:
            case WalletHistoryType.TRANSFER_ACCOUNT_TO_ACCOUNT_v2:
                translated = transate(
                    "TRANSACTION_DETAIL",
                    "TRANSFERT_ACCOUNT_TO_ACCOUNT_OUT",
                    {
                        amount_currency_in: `${this.formatAmountWithCurrency(
                            this.user_wallet,
                            this.amount,
                        )}`,
                        account_name: `${this.getAccountOutName()}`,
                        amount_currency_out: `${this.formatAmountWithCurrency(
                            this.target,
                            this.amount,
                        )}`,
                    },
                );
                return translated === "TRANSFERT_ACCOUNT_TO_ACCOUNT_IN"
                    ? this.description
                    : translated;
            default:
                break;
        }

        return this.description;
    };
}

export interface OTransactionCount {
    deposit: number;
    all: number;
    withdraw: number;
    all_transactions: number;
    moderation: number;
    account_18: number;
    card: number;
}

export enum WalletHistoryType {
    DEPOSIT = "deposit",
    WITHDRAW = "withdraw",
    WITHDRAW_MANUAL = "withdraw_manual",
    STAKING = "staking",
    STOP_STAKING = "stop_staking",
    UNSTAKING = "unstaking",
    TRANSFER_IN = "transferin",
    TRANSFERT_OUT = "transferout",
    TRANSFER_IN_V2 = "transferin_v2",
    TRANSFER_IN_V3 = "transferin_v3",
    TRANSFER_ACCOUNT_TO_ACCOUNT = "transferin_account_to_account_deposit",
    TRANSFER_ACCOUNT_TO_ACCOUNT_v2 = "transferin_account_to_account_withdraw",
    TRANSFER_ACCOUNT_TO_ACCOUNT_RECEPTION = "transferin_account_to_account_deposit_reception",
    TRANSFER_ACCOUNT_TO_ACCOUNT_v2_RECEPTION = "transferin_account_to_account_withdraw_reception",
    TRANSFERT_OUT_V2 = "transferout_v2",
    TRANSFERT_OUT_V3 = "transferout_v3",
    COMMISSION = "commission",
    SWAPIN = "swapin",
    SWAPOUT = "swapout",
    DAILY_ROI = "daily_roi",
    DAILY_KNT = "daily_knt",
    ORDER_PACKBOOSTER = "order_packbooster",
    DEPOSIT_VISA = "deposit_visa",
    DEPOSIT_VIREMENT = "deposit_virement",
    DEPOSIT_KALYPAY = "deposit_kalypay",
    DEPOSIT_CRYPTO = "deposit_crypto",
    WITHDRAW_KALYPAY = "withdraw_kalypay",
    WITHDRAW_CRYPTO = "withdraw_crypto",
    RANK_BONUS = "rank_bonus",
    POOL_BONUS = "pool_bonus",
    COMMISSION_PURCHASE = "commission_purchase",
    COMMISSION_LOYALTY = "commission_loyalty",
    COMMISSION_SWAP = "commission_swap",
    COMMISSION_WITHDRAW = "commission_withdraw",
    COMMISSION_SERVICE_CHARGE = "commission_service_charge",
    PURCHASE_FIDELITY = "purchase_fidelity",
    CASHBACK_KLC = "cashback_klc",
    SOLDE_MIGRATION = "solde_migration",
    GAIN_A_TERM = "gain_a_term",
    PAYMENT = "payment",
    PAYMENT_RECEPTION = "payment_reception",
    JACKPOT_PAYOUT = "jackpot_payout",
    JACKPOT_PAYIN = "jackpot_payin",
    WITHDRAW_VIREMENT = "withdraw_virement",
    WITHDRAW_PAYEER = "withdraw_payeer",
    DEPOSIT_PAYEER = "deposit_payeer",
    PURCHASE_FIDELITY_RECURRENCE = "purchase_fidelity_recurrence",
    CARD_TRANSANCTON = "card_transaction",
}

export interface ICSVTransaction {
    type: string;
    date: string;
    amount: number;
    crypto_in: string;
    crypto_out: string;
    fee: number;
    crypto_fee: string;
    swapping_plateform: string;
    description: string;
    label: string;
}

export enum WalletHistoryStatus {
    INITIALIZED = "initialized",
    PENDING = "pending",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
}

export const OUT_TRANSACTIONS = [
    WalletHistoryType.WITHDRAW,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.TRANSFERT_OUT,
    WalletHistoryType.SWAPOUT,
    WalletHistoryType.ORDER_PACKBOOSTER,

    WalletHistoryType.ORDER_PACKBOOSTER,
    WalletHistoryType.WITHDRAW,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.WITHDRAW_MANUAL,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_VIREMENT,

    WalletHistoryType.JACKPOT_PAYOUT,
    WalletHistoryType.TRANSFER_ACCOUNT_TO_ACCOUNT_v2,
    WalletHistoryType.TRANSFER_ACCOUNT_TO_ACCOUNT,
    WalletHistoryType.WITHDRAW_PAYEER,
    WalletHistoryType.TRANSFERT_OUT_V2,
    WalletHistoryType.TRANSFERT_OUT_V3,

    WalletHistoryType.STAKING,
    WalletHistoryType.PAYMENT,
    WalletHistoryType.PURCHASE_FIDELITY,
    WalletHistoryType.PURCHASE_FIDELITY_RECURRENCE,
];

export const COMMISSION_TRANSACTIONS = [
    WalletHistoryType.COMMISSION,
    WalletHistoryType.COMMISSION_LOYALTY,
    WalletHistoryType.COMMISSION_PURCHASE,
    WalletHistoryType.COMMISSION_SERVICE_CHARGE,
    WalletHistoryType.COMMISSION_SWAP,
    WalletHistoryType.COMMISSION_WITHDRAW,
];
