import React from "react";

interface SwitchProps {
    checked: boolean;
    disabled?: boolean;
    changeState?: (state: boolean) => void;
    switch_on: string;
    switch_off: string;
}

const SwitchTex = ({
    checked,
    changeState,
    disabled,
    switch_on,
    switch_off,
}: SwitchProps) => {
    // const [value, setValue] = useState<boolean>(false);
    return (
        <div
            className={`checkbox flex items-center cursor-pointer justify-between w-[1.8rem] h-[0.8rem] rounded-[20px] flex-row-reverse my-center ${
                disabled &&
                "!cursor-not-allowed !bg-gray-100 !text-gray-300 !border !border-gray-300"
            }  ${!checked && "checkbox--on"}`}
            role="button"
            onClick={() => {
                if (changeState) {
                    changeState(!checked);
                }
            }}
        >
            <div className="w-[0.6rem] h-full rounded-full transition-transform bg-gray-200"></div>
            <span className="mx-1 text-[8px]">
                {checked ? switch_on : switch_off}
            </span>
        </div>
    );
};

export default SwitchTex;
