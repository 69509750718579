import React from "react";
import { Outlet } from "react-router-dom";
import TabNav, { TabItem } from "../../../../Components/Extra/Tab/TabNav";
import BreadCrumbV2, {
    Ariane,
} from "../../../../Layouts/Account/BreadCrumb/BreadCrumbV2";

const navigation: TabItem[] = [
    {
        path: "infinity",
        name: "Infinity",
    },
    // {
    //     path: "fiat",
    //     name: "Fiat",
    // },
    {
        path: "currency",
        name: "Currency",
    },
    {
        path: "swap",
        name: "Swap",
    },
    // {
    //     path: "transfer",
    //     name: "Transfer",
    // },
];
// BasicData.BasicData
const AssetConfig = () => {
    const Arianes: Ariane[] = [
        {
            path: "#",
            name: "Admin",
        },
        {
            path: "/admin/setting",
            name: "Setting",
        },
        {
            path: "",
            name: "Asset",
        },
    ];

    return (
        <>
            <h2 className="intro-y mt-10 bg-white px-3 p-2 rounded text-lg font-medium">
                Asset Config
            </h2>
            <div className="mt-5 pt-5">
                <TabNav items={navigation} />
                <div className="intro-y col-span-12 box">
                    <div className="flex flex-col border-b border-slate-200/60 pb-5 lg:flex-row relative p-3">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssetConfig;
