import React from "react";
import DataTablePaginate from "./DataTablePaginate";

const DataTablePagination = ({
    currentPage,
    setCurrentPage,
    nbPage,
    handlePaginationChange,
    className,
}: {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    nbPage: number;
    handlePaginationChange?: (page: number) => void;
    className?: string;
}) => {
    return (
        <div
            className={`intro-y col-span-12 flex flex-wrap items-center justify-end sm:flex-row sm:flex-nowrap ${className}`}
        >
            <DataTablePaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handlePaginationChange={handlePaginationChange}
                nbPage={nbPage}
            />
        </div>
    );
};

export default DataTablePagination;
