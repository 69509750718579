import React from "react";
import "react-phone-input-2/lib/bootstrap.css";

import translator from "../../Extra/Translation/Translate";
import { ModalContext } from "../../Extra/Modal/ModalContext";
import CreateCardVirtual from "./createCardVirtual";

const BtnAddCardVirtualModal = () => {
    const { handleModal } = React.useContext(ModalContext);
    const { translate } = translator();
    return (
        <button
            type="button"
            className="btn-perfect py-2 px-4 rounded-lg text-sm"
            onClick={() => handleModal(<CreateCardVirtual type="MODAL" />)}
        >
            <span>{translate("WALLET", "ADD_CARD")}</span>
        </button>
    );
};

export default BtnAddCardVirtualModal;
