import { useEffect, useMemo, useState } from "react";
import SettingService from "../../../Services/Setting/SettingService";
import FidelityModel from "../../../Models/Fidelity";
import FidelityService from "../../../Services/FidelityService/FidelityService";
import { CARD_VIRTUEL_TYPE } from "../../../Models/UserCard";
import { SubscribeCardVirtualContent } from "./SubscribeView";
import { useCardContext } from "../../../App/Provider/CardProvider";

const FormCardVirtual = ({
    cardType,
    hideTitle = false,
}: {
    cardType: CARD_VIRTUEL_TYPE;
    hideTitle?: boolean;
}) => {
    const {
        userCards,
        refreshUserCards,
        selectedCard,
        setSelectedCard,
        userCards2,
    } = useCardContext();
    const [baseCurrency, setBaseCurrency] = useState<any>(null);
    const [currentLoyalty, setCurrentLoyalty] = useState<any | undefined>(
        undefined,
    );
    const getBaseCurrency = async () => {
        try {
            const res = await SettingService.getBaseCurrency();
            setBaseCurrency(res);
        } catch (err) {
            console.error(err);
        }
    };
    const getActiveFidelity = async () => {
        try {
            const res = await FidelityService.getActiveFidelity();
            if (res) setCurrentLoyalty(res?.fidelity);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        getBaseCurrency();
        // if (!setSelectedCard) {
        //     getActiveFidelity();
        // } else {
        //     setCurrentLoyalty(selectedCard?.fidelity);
        // }
        getActiveFidelity();
        // console.log({ "selectedCard *****": selectedCard?.fidelity });
        // if (selectedCard?.fidelity) {
        //     setCurrentLoyalty(selectedCard?.fidelity);
        // } else {
        //     setCurrentLoyalty(userCards2[0]?.fidelity);
        // }
    }, []);

    const selected_card = useMemo(() => {
        return userCards2[0]?.fidelity;
    }, [selectedCard, userCards2]);

    useEffect(() => {
        setCurrentLoyalty(selected_card);
    }, [selected_card]);
    // return (
    //     baseCurrency &&
    //     currentLoyalty && (
    //         <SubscribeCardVirtualContent
    //             props={currentLoyalty}
    //             current_fidelity={currentLoyalty}
    //             base_currency_system={baseCurrency}
    //             cardType={cardType}
    //             hideTitle={hideTitle}
    //         />
    //     )
    // );
    return (
        baseCurrency &&
        currentLoyalty && (
            <SubscribeCardVirtualContent
                props={currentLoyalty}
                current_fidelity={currentLoyalty}
                base_currency_system={baseCurrency}
                cardType={cardType}
                hideTitle={hideTitle}
            />
        )
    );
};

export default FormCardVirtual;
