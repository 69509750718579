import React from "react";
import { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { CustomMessage } from "../../../Models/Constants";
import { IUserSignUp, UserType } from "../../../Models/User";
import alert_message from "../../../Services/Alert/AlertMessage";
import { signUp } from "../../../Services/Authentication/Authentication";
import translator from "../../Extra/Translation/Translate";
import { Cookies } from "react-cookie";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";
import Select from "react-tailwindcss-select";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import SelectInput from "../../Extra/Input/SelectInput";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";
type RegisterComponentProps = {
    parrain?: string;
};

const RegisterComponent = ({ parrain }: RegisterComponentProps) => {
    const key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const navigate = useNavigate();
    const [processing, setProcessing] = useState<boolean>(false);
    const cookies = new Cookies();
    const [username, setUsername] = useState<string>("");
    if (parrain) {
        cookies.set("parrain", parrain);
    }
    const [uidParrain, setUidParrain] = useState<string>(
        parrain
            ? parrain
            : cookies.get("parrain")
              ? cookies.get("parrain")
              : "",
    );
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [repeatPassword, setRepeatPassword] = useState<string>("");
    const [agree, setAgree] = useState<boolean>(false);
    const [message, setMessage] = useState<CustomMessage | null>();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<number>(0);
    const { translate } = translator();
    const { signUpAsync } = AppAuthentication();

    const [token, setToken] = useState<string | null>(null);

    const onVerify = useCallback((token: any) => {
        setToken(token);
    }, []);

    const type: DOption[] = [
        {
            label: translate("UserType", UserType.PARTICULIER),
            value: UserType.PARTICULIER,
        },
        {
            label: translate("UserType", UserType.ENTERPRISE),
            value: UserType.ENTERPRISE,
        },
    ];
    const [userType, setUserType] = useState<DOption>(type[0]);
    const [typeUser, setTypeUser] = useState<string>(UserType.PARTICULIER);
    const [isEnterprise, setIsEnterprise] = useState<boolean>(false);
    const [entreprise, setEntreprise] = useState<string>("");
    const [siret, setSiret] = useState<string>("");

    const handleChangeUserType = (type: any) => {
        setUserType(type);
        setTypeUser(type.value);
        if (type.value == UserType.ENTERPRISE) {
            setIsEnterprise(true);
        } else {
            setIsEnterprise(false);
        }
    };

    const handleSignUp = async (e: any) => {
        e.preventDefault();
        if (token) {
            setProcessing(true);
            const payload: IUserSignUp = {
                email: email,
                first_name: firstName,
                last_name: lastName,
                username: email,
                password: password,
                uid_parrain: uidParrain,
                token: token,
                type: typeUser,
                entreprise: entreprise,
                siret: siret,
            };

            try {
                const promise = signUpAsync(payload, repeatPassword, translate);
                alert_message.promise("Registration ...", promise).then(() => {
                    navigate("/login");
                });
            } catch (error: any) {}

            setProcessing(false);
            setMessage(null);
        } else {
            const recaptcha_msg: CustomMessage = {
                type: "error",
                message: "Please verify that you are not a robot.",
            };

            setMessage(recaptcha_msg);
        }
        setToken(null);
        setRefreshReCaptcha(refreshReCaptcha + 1);
    };

    useEffect(() => {
        setTimeout(function () {
            setRefreshReCaptcha(refreshReCaptcha + 1);
        }, 500);
    }, []);

    useEffect(() => {
        if (token) setMessage(null);
    }, [token]);

    return (
        <div className="m-auto w-full rounded-md flex justify-center items-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none mdg-signup-box">
            <form onSubmit={(e) => handleSignUp(e)}>
                <div className="relative">
                    <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl">
                        {translate("SignIn", "Register")}
                    </h2>
                    <div className="intro-x mt-8">
                        <div className="relative w-full group w-selects">
                            <SelectInput
                                id="id-user-type"
                                options={type}
                                value={userType}
                                onChange={(val: any) =>
                                    handleChangeUserType(val)
                                }
                                required
                            />
                            <label
                                htmlFor="select"
                                className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Register", "Individual_Company")}
                            </label>
                        </div>

                        {isEnterprise && (
                            <div className="relative mt-4">
                                <input
                                    type="text"
                                    id="username-field"
                                    className="border-1  peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=" "
                                    value={entreprise}
                                    onChange={(e) =>
                                        setEntreprise(e.target.value)
                                    }
                                    required
                                />
                                <label
                                    htmlFor="username-field"
                                    className="absolute top-2 left-1 z-1 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("Register", "Company_Name")}
                                </label>
                            </div>
                        )}

                        {isEnterprise && (
                            <div className="relative mt-4">
                                <input
                                    type="text"
                                    id="siret-field"
                                    className="border-1  peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=" "
                                    value={siret}
                                    onChange={(e) => setSiret(e.target.value)}
                                    required
                                />
                                <label
                                    htmlFor="siret-field"
                                    className="absolute top-2 left-1 z-1 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate(
                                        "Register",
                                        "Registration_Number",
                                    )}
                                </label>
                            </div>
                        )}

                        <div className="relative mt-4">
                            <input
                                type="email"
                                id="email-field"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <label
                                htmlFor="email-field"
                                className="absolute top-2 left-1 z-1 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Register", "Email")}
                            </label>
                        </div>
                        <div className="relative mt-4">
                            <input
                                type="text"
                                id="firstname-field"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                            <label
                                htmlFor="firstname-field"
                                className="absolute top-2 left-1 z-1 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Register", "First_name")}
                            </label>
                        </div>
                        <div className="relative mt-4">
                            <input
                                type="text"
                                id="lastname-field"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                            <label
                                htmlFor="lastname-field"
                                className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Register", "Last_name")}
                            </label>
                        </div>
                        <div className="relative mt-4">
                            <input
                                type="password"
                                id="password"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <label
                                htmlFor="password"
                                className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Register", "Password")}
                            </label>
                        </div>
                        <div className="relative mt-4">
                            <input
                                type="password"
                                id="confirm-password"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={repeatPassword}
                                onChange={(e) =>
                                    setRepeatPassword(e.target.value)
                                }
                                required
                            />
                            <label
                                htmlFor="confirm-password"
                                className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Register", "Confirm_password")}
                            </label>
                        </div>
                    </div>
                    <div className="intro-x mt-4 flex items-center text-xs text-slate-600 sm:text-sm">
                        <input
                            id="agreement-field"
                            type="checkbox"
                            className="form-check-input mr-2 border"
                            required
                            checked={agree}
                            onChange={(e) => {
                                setAgree(e.target.checked);
                            }}
                        />
                        <label
                            className="cursor-pointer select-none"
                            htmlFor="agreement-field"
                        >
                            {translate("Register", "Agree_Infinexia")}
                            <a
                                className="text-slate-500 mx-1"
                                href="https://infinexia.com/terms-and-conditions"
                            >
                                {translate("SignIn", "Terms_Conditions")}
                            </a>{" "}
                            &
                            <a
                                className="text-slate-500 mx-1"
                                href="https://infinexia.com/privacy-policy"
                            >
                                {translate("SignIn", "Privacy_Policy")}
                            </a>
                        </label>
                    </div>
                    <div className="intro-x flex justify-center text-center xl:text-left">
                        <GoogleReCaptcha
                            onVerify={onVerify}
                            refreshReCaptcha={refreshReCaptcha}
                        />
                    </div>
                    <div className="intro-x mt-2 h-5 flex justify-center text-center xl:mt-4 xl:text-left">
                        <div
                            className={
                                token === "success"
                                    ? "text-green-600"
                                    : "text-slate-500"
                            }
                        >
                            {token ? (
                                <></>
                            ) : (
                                <>{translate("RECAPTCHA", "VERIFICATION")}</>
                            )}
                        </div>
                    </div>
                    <div className="intro-x mt-2 text-center">
                        {message && !token && (
                            <div
                                className={
                                    message.type === "success"
                                        ? "text-green-600"
                                        : "text-red-500"
                                }
                            >
                                {message.message}
                            </div>
                        )}
                    </div>
                    <div className="intro-x mt-2 xl:flex xl:justify-center gap-3 lg:flex lg:justify-center text-center xl:text-left block h-[auto]">
                        <button
                            className="btn btn-perfect mt-3 w-56 border-[3px] border-[3px] border-[#3695D2] h-[40px]"
                            type="submit"
                            disabled={processing}
                        >
                            {processing
                                ? "Processing ..."
                                : translate("SignIn", "Register")}
                        </button>
                        <button
                            className="btn bg-transparent mt-3 w-56 text-black border-2 hover:!border-[3px] border-[#3695D2]  h-[40px] dark:!text-white dark:!border-2 dark:!border-slate-600 dark:!rounded-lg"
                            onClick={() => navigate("/login")}
                            disabled={processing}
                        >
                            {translate("Register", "Sign_in")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default RegisterComponent;
