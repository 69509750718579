import { useAssets } from "../../../App/Provider/AssetsProvider";
import { UserType } from "../../../Models/User";
import { UserCryptoWallet, Wallet } from "../../../Models/UserWallet";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import UtilsService from "../../../Services/UtilsService";
import DepositSvg from "../../Extra/Svg/DepositSvg";
import SwapSvg from "../../Extra/Svg/SwapSvg";
import WithdrawalSvg from "../../Extra/Svg/WithdrawalSvg";
import translator from "../../Extra/Translation/Translate";
import { NavLink, useParams } from "react-router-dom";
// import ChartComponent from './ChartComponent';
import ChartComponent2 from "./ChartComponent";
import { useEffect, useState } from "react";
import MarketTrendService from "../../../Services/Wallets/MarketTrendService";
import { ICryptoPrices } from "../../../Models/Crypto";
import { chartColors } from "../../chart/chart.config";
import useTheme from "../../../Services/Hooks/useTheme";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
const ChartCrypto = () => {
    const { user } = useAuthentication();
    const { currentAsset, setCurrentAsset, assets } = useAssets();
    const { abbrev } = useParams<{ abbrev: string }>();
    const { mode } = useTheme();
    const darkMode = mode === AppMode.DARK;
    const { tooltipBgColor, tooltipBorderColor, tooltipBodyColor } =
        chartColors;
    const wallet = assets.find((c) => c.abbreviation === abbrev);
    if (!abbrev || !wallet) {
        return <></>;
    }
    const [chartData, setChartData] = useState<any>(null);
    const fetchData = async () => {
        try {
            const data = [];
            if (abbrev) {
                data.push(abbrev);
            }

            const payload: ICryptoPrices = {
                abbreviations: data,
            };
            const res = await MarketTrendService.getCryptoPrices(payload);
            if (res) setChartData(res[0]);
        } catch (error) {
            console.error("Error fetching crypto prices:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [abbrev]);
    const { translate } = translator();
    const is_major =
        user?.type === UserType.JOIN ||
        (user != undefined &&
            !(
                user.tutor ||
                UtilsService.isMinor(new Date(user.date_of_birth || ""))
            ));
    const can_use_crypto =
        wallet.type === "fiat" || is_major || (user && !!user.can_use_crypto);
    const transfert_conditon =
        (is_major || (user && !!user.allow_the_child_to_transfer_assets)) &&
        can_use_crypto;

    const walletLogo = wallet?.logo
        ? UtilsService.getPhotoUrl(wallet.logo)
        : "";
    const after_comma = wallet?.type === "fiat" ? 2 : 8;
    const solde = wallet.wallet.solde;
    const valid_solde = solde > 0;
    const usd_price = wallet.wallet.solde * wallet.current_price;
    const deposit_conditon =
        (is_major || (user && !!user.allow_child_deposit_assets)) &&
        can_use_crypto;

    const exhange_conditon =
        (is_major || (user && !!user.allow_child_to_trade_assets)) &&
        can_use_crypto;
    const crypto_withdraw_link =
        valid_solde && wallet.withdraw_status === true
            ? `/withdraw/${wallet.abbreviation}/`
            : ``;

    const deposit_link =
        wallet.deposit_status == true ? `/deposit/${wallet.abbreviation}` : "";
    const swap_link_class =
        wallet.swap_status === true
            ? "btn btn-perfect md-w-13 !sm-w-auto"
            : "btn btn-perfect !cursor-not-allowed md-w-13 !sm-w-auto";
    const withdraw_link_class =
        valid_solde && wallet.withdraw_status === true
            ? "btn btn-perfect md-w-13 !sm-w-auto"
            : "btn btn-perfect !cursor-not-allowed md-w-13 !sm-w-auto";
    const deposit_link_class =
        wallet.deposit_status == true
            ? "btn btn-perfect md-w-13 !sm-w-auto"
            : "btn btn-perfect !cursor-not-allowed md-w-13 !sm-w-auto";
    const crypto_swap_link =
        wallet.swap_status === true ? `/exchange/${wallet.abbreviation}/` : ``;
    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 lg:col-span-12 ">
                    <div className="intro-y flex justify-between w-full">
                        <div className="w-[50%]">
                            <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3">
                                <div className="flex justify-start p-2 text-lg font-bold">
                                    {wallet.name}
                                </div>
                            </div>
                            <div className="flex justify-between w-full">
                                <div className="flex items-center">
                                    <div className="sm:h-8 sm:w-8 md:h-10 md:w-10 lg:h-10 lg:w-10 xl:h-10 xl:w-10 2xl:h-10 2xl:w-10">
                                        <img
                                            src={walletLogo}
                                            alt="wallet logo"
                                            className="h-full"
                                        />
                                    </div>
                                    <div className="block pl-2">
                                        <div className="text-medium sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                                            Solde du portefeuille
                                        </div>
                                        <div className="text-slate-400/60 sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm flex">
                                            <div className="text-right">
                                                {`${wallet.abbreviation} ${UtilsService.formatAmount(
                                                    wallet.wallet.solde,
                                                    after_comma,
                                                )}  ${
                                                    wallet.personal_asset_detail
                                                        ? wallet.currency
                                                        : wallet.abbreviation
                                                } ${UtilsService.getConvertedFormatedAmount(
                                                    usd_price,
                                                    user?.base_currency,
                                                    after_comma,
                                                )} `}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="block pl-2">
                                        <div className="text-medium sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                                            <span className="font-bold">
                                                {" "}
                                                5485565 $
                                            </span>
                                        </div>
                                        <div className="text-slate-400/60 sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm flex">
                                            <div className="text-right"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {/* <ChartComponent /> */}
                                {chartData && (
                                    <ChartComponent2 assetData={chartData} />
                                )}
                            </div>
                        </div>
                        <div className="w-[50%]">
                            <div className="px-0 py-1 md:!p-3 md:!max-h-[500px] md:!max-h-auto overflow-auto">
                                <div className=" h-full">
                                    <h3 className="">Statistiques</h3>
                                    <div className="box py-1 text-center h-full mt-3">
                                        <div className="intro-y col-span-12 pb-2 pt-1 mx-3 my-2">
                                            <div className="flex justify-between space-x-1 my-3">
                                                <span className="">
                                                    capitalisation boursière
                                                </span>
                                                <span className="font-medium  text-right">
                                                    1,14T $
                                                </span>
                                            </div>
                                            <div className="flex justify-between space-x-1 my-3">
                                                <span className="">
                                                    Classement de la
                                                    capitalisation boursière
                                                </span>
                                                <span className="font-medium  text-right">
                                                    #1
                                                </span>
                                            </div>
                                            <div className="flex justify-between space-x-1 my-3">
                                                <span className="">
                                                    Approvisionnement en
                                                    circulation
                                                </span>
                                                <span className="font-medium  text-right">
                                                    19,75M
                                                </span>
                                            </div>
                                            <div className="flex justify-between space-x-1 my-3">
                                                <span className="">
                                                    Volume de negociation
                                                </span>
                                                <span className="font-medium text-right">
                                                    34,6B$
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center h-full py-2 flex justify-end">
                                        <div className="py-1 h-full flex items-center space-x-2 ">
                                            <div className="m-1">
                                                {deposit_conditon ? (
                                                    <NavLink
                                                        to={deposit_link}
                                                        className={
                                                            deposit_link_class
                                                        }
                                                        state={{
                                                            wallet: wallet,
                                                        }}
                                                    >
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Recharge",
                                                        )}
                                                    </NavLink>
                                                ) : (
                                                    <button
                                                        className={
                                                            deposit_link_class
                                                        }
                                                        disabled
                                                    >
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Recharge",
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                            <div className="m-1">
                                                {exhange_conditon ? (
                                                    <NavLink
                                                        to={crypto_swap_link}
                                                        className={
                                                            swap_link_class
                                                        }
                                                        state={{
                                                            wallet: wallet,
                                                        }}
                                                    >
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Echange",
                                                        )}
                                                    </NavLink>
                                                ) : (
                                                    <button
                                                        className={
                                                            swap_link_class
                                                        }
                                                        disabled
                                                    >
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Echange",
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                            <div className="m-1">
                                                {transfert_conditon ? (
                                                    <NavLink
                                                        to={
                                                            crypto_withdraw_link
                                                        }
                                                        className={
                                                            withdraw_link_class
                                                        }
                                                        state={{
                                                            wallet: wallet,
                                                        }}
                                                    >
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Retrait",
                                                        )}
                                                    </NavLink>
                                                ) : (
                                                    <button
                                                        className={
                                                            withdraw_link_class
                                                        }
                                                        disabled
                                                    >
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Retrait",
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChartCrypto;
