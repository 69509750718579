import FidelityModel from "./Fidelity";
import OrderCard from "./OrderCard";

export enum CARD_TYPE {
    BLUE = "blue",
    GOLD = "gold",
    BLACK = "black",
    VIRTUAL_CARD = "virtual_card",
    VIRTUAL_CARD_U = "virtual_card_u",
}

export enum USER_CARD_STATUS {
    PREACTIVE = "preactive",
    ACTIVE = "active",
    DESACTIVE = "desactive",
    BLOQUED = "bloqued",
}

export interface ICardRequestPayload {
    last_4_digits: string;
    transaction_password: string;
}

export interface CardTransactionPayload {
    last_4_digits: string;
    transaction_password: string;
    wallet: string;
    amount: number;
}
export default class UserCard {
    card_type!: CARD_TYPE;
    status!: USER_CARD_STATUS;
    last_4_digits!: string;
    last_balance!: number;
    last_name!: string;
    first_name!: string;
    logo!: string;
    fidelity?: FidelityModel;
    order?: OrderCard;

    constructor(
        card_type: CARD_TYPE,
        status: USER_CARD_STATUS,
        last_4_digits: string,
        last_balance: number,
        last_name: string,
        first_name: string,
        logo: string,
        fidelity?: FidelityModel,
        order?: OrderCard,
    ) {
        this.card_type = card_type;
        this.status = status;
        this.last_4_digits = last_4_digits;
        this.last_balance = last_balance;
        this.last_name = first_name;
        this.first_name = last_name;
        this.logo = logo;
        this.fidelity = fidelity;
        this.order = order;
    }

    getName() {
        switch (this.card_type) {
            case CARD_TYPE.BLUE:
                return "Blue";
            case CARD_TYPE.GOLD:
                return "Gold";
            case CARD_TYPE.BLACK:
                return "Black";
            case CARD_TYPE.VIRTUAL_CARD:
                return "virtual_card";
            case CARD_TYPE.VIRTUAL_CARD_U:
                return "virtual_card_u";
        }
    }
}

export enum CARD_VIRTUEL_TYPE {
    CARD_VIRTUEL = "CARD_VIRTUEL",
    CARD_VIRTUEL_U = "CARD_VIRTUEL_U",
}
