import React, {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import WalletService from "../../Services/Wallets/WalletService";
import { UserCryptoWallet } from "../../Models/UserWallet";
import useAuthentication from "../../Services/Authentication/useAuthentication";

interface ContextProps {
    assets: UserCryptoWallet[];
    currentAsset: UserCryptoWallet | undefined;
    refreshAssets: () => any;
    setCurrentAsset: (asset: UserCryptoWallet | undefined) => any;
    walletSynchronisation: boolean;
    refreshed: number;
    getGlobalBalance: () => number;
    getFiatBalance: () => number;
    getCardBalance: () => number;
    getCryptoBalance: () => number;
    reload: boolean;
    setReload: Dispatch<SetStateAction<boolean>>;
    filter: string;
    setFilter: Dispatch<SetStateAction<string>>;
    getPrincipalAccount: () => undefined | UserCryptoWallet;
}

const AssetsContext = createContext<ContextProps>({
    assets: [],
    currentAsset: undefined,
    refreshAssets: () => true,
    setCurrentAsset: (val: UserCryptoWallet | undefined) => {
        return;
    },
    walletSynchronisation: false,
    refreshed: 0,
    getGlobalBalance: () => 0,
    getFiatBalance: () => 0,
    getCardBalance: () => 0,
    getCryptoBalance: () => 0,
    reload: false,
    setReload: (): boolean => false,
    filter: "",
    setFilter: (): string => "",
    getPrincipalAccount: () => undefined,
});

export default function AssetsProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const { user } = useAuthentication();
    const [walletSynchronisation, setWalletSynchronisation] =
        useState<boolean>(false);
    const [nbCall, setNbCall] = useState<number>(0);
    const [assets, setAssets] = useState<UserCryptoWallet[]>([]);
    const [asset, setAsset] = useState<UserCryptoWallet | undefined>(undefined);
    const [filter, setFilter] = useState<string>("fiat");
    const [reload, setReload] = useState<boolean>(false);

    const refreshAssets = async () => {
        setNbCall(nbCall + 1);
        setWalletSynchronisation(true);

        const data = await WalletService.getUserWallet();
        setWalletSynchronisation(false);
        setAssets(data);
    };

    useEffect(() => {
        if (nbCall === 0) {
            refreshAssets();
        }
    }, [nbCall]);

    const getGlobalBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = asset.wallet.solde;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };
    const getFiatBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = asset.type === "fiat" ? asset.wallet.solde : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };
    const getCardBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };
    const getCryptoBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "crypto" ? asset.wallet.solde : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    const getPrincipalAccount = () => {
        const currency = user?.base_currency?.currency || "USD";
        const principal = assets.find(
            (asset) => asset.abbreviation === currency,
        );

        return principal;
    };

    return (
        <AssetsContext.Provider
            value={{
                walletSynchronisation,
                assets,
                refreshAssets,
                getGlobalBalance,
                getFiatBalance,
                getCardBalance,
                getCryptoBalance,
                refreshed: nbCall,
                currentAsset: asset,
                setCurrentAsset: setAsset,
                filter,
                setFilter,
                reload,
                setReload,
                getPrincipalAccount,
            }}
        >
            {children}
        </AssetsContext.Provider>
    );
}

export const useAssets = () => useContext(AssetsContext);
