import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../globalState/hook";
import { useEffect } from "react";
import {
    ChangeAppLanguage,
    Language,
} from "../globalState/reducers/Language.reducer";

export default function LanguageProvider() {
    const { lang } = useParams();

    const dispach = useAppDispatch();
    const language = useAppSelector((store) => store.language.language);
    const { pathname } = useLocation();
    const history = useNavigate();

    useEffect(() => {
        if (lang && Object.values(Language).includes(lang as any)) {
            dispach(ChangeAppLanguage({ language: lang as any }));
        } else {
            // dispach(ChangeAppLanguage({ language: Language.FR }));

            history("/" + language + pathname);
        }
    }, []);

    useEffect(() => {
        if (lang != language) {
            if (lang) {
                history(
                    pathname
                        .replace(lang, language == Language.EN ? "" : language)
                        .replace("//", "/"),
                );
            } else {
                history(
                    language == Language.EN
                        ? pathname
                        : "/" + language + pathname,
                );
            }
        } else if (!lang) {
            if (language != Language.EN) {
                history("/" + language + pathname);
            }
        }
    }, [language, pathname]);

    return (
        <>
            <Outlet />
        </>
    );
}
