import { DatatableFilter } from "../../Models/DatatableFilter";
import { IuserCryptoSetting } from "../../Models/SubAccountRequest";
import User, {
    DirectActifCount,
    IAddAffiliation,
    IEditProfil,
    IUserPasswordReset,
    IUserRequestResetPassword,
    IUserSetting,
    PublicUserDta,
    ThemeSetting,
    WidgetCard,
    WidgetSetting,
} from "../../Models/User";
import PartenarUser from "../../Models/UserPartenar";
import SearchUser from "../../Models/UserSearch";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";
const map = (item: any): User => {
    const user = new User(
        item.uid,
        item.username,
        item.last_name,
        item.first_name,
        item.email,
        item.reinvest_auto,
        item.hide_zero_amount,
        item.country,
        item.language,
        [],
    );

    user.scopes = item.scopes;
    user.created_at = item.created_at;
    user.cag = item.cag;
    user.cap = item.cap;
    user.photo = item.photo;
    user.is_active = item.is_active;
    user.kyc = item.kyc || "";
    user.type = item.type;
    user.siret = item.siret;
    user.entreprise = item.entreprise;
    user.fidelity = item.fidelity;
    user.date_of_birth = item.date_of_birth;
    user.city = item.city;
    user.post_code = item.post_code;
    user.address = item.address;
    user.phone_country_code = item.phone_country_code;
    (user.phone = item.phone), (user.country = item.country);
    user.monthly_expenses_limit = item.monthly_expenses_limit;
    user.can_use_crypto = item.can_use_crypto;
    user.can_transfert_crypto = item.can_transfert_crypto;
    user.can_close_earning = item.can_close_earning;
    user.can_create_jackpot = item.can_create_jackpot;
    user.global_solde = item.global_solde;
    user.principal_solde = item.principal_solde;
    user.base_currency = item.base_currency;
    user.join_parents = item.join_parents;

    return user;
};

const mapPartenar = (item: any): PartenarUser => {
    const user = new PartenarUser(
        item.uid,
        item.username,
        item.last_name,
        item.first_name,
        item.email,
    );

    user.photo = item.photo;
    user.type = item.type;
    user.entreprise = item.entreprise;

    return user;
};

const getAll = async (): Promise<Array<User>> => {
    return axios
        .get(`/user`)
        .then(({ data }) => {
            const users: User[] = data.map((item: any) => map(item));
            return users;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getPersonnalAccount = async (): Promise<string> => {
    return axios
        .get(`/user/get-personal-account`)
        .then(({ data }) => {
            return data.uid;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllWithFidelity = async (filter: DatatableFilter): Promise<any> => {
    return axios
        .get(`/user/with-fidelity`, { params: filter })
        .then(({ data }) => {
            // const users: User[] = data.map((item: any) => map(item));
            // return users;
            const assets: User[] = data.data.map((item: any) => map(item));

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getPartenar = async (search: string): Promise<Array<PartenarUser>> => {
    return axios
        .get(`/user/search-all`, { params: { search } })
        .then(({ data }) => {
            const users: PartenarUser[] = data.map((item: any) =>
                mapPartenar(item),
            );
            return users;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllTUsersFilter = async (filter: DatatableFilter): Promise<any> => {
    return axios
        .get(`/user/get-all`, { params: filter })
        .then(({ data }) => {
            const assets: User[] = data.data.map((item: any) => map(item));

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllTAccount18Filter = async (
    filter: DatatableFilter,
): Promise<any> => {
    return axios
        .get(`/user/account-18`, { params: filter })
        .then(({ data }) => {
            const assets: User[] = data.data.map((item: any) => map(item));

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getTopFive = async (): Promise<any> => {
    return axios
        .get(`/user/dashboard-info`)
        .then(({ data }) => {
            const users: User[] = data[0].map((item: any) => map(item));

            return {
                users: users,
                cag: data[1]["cag"],
                user_count: data[1]["user_count"],
                order_count: data[2]["order_count"],
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (id?: string) => {
    return axios
        .delete(`/user/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const forgotPassword = async (payload: IUserRequestResetPassword) => {
    try {
        const result = await axios.post(`/user/reset-password-request`, {
            ...payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const resetPassword = async (payload: IUserPasswordReset) => {
    try {
        const result = await axios.post("/user/reset-password", {
            ...payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getAffiliates = async (): Promise<PublicUserDta[]> => {
    return axios
        .get(`/user/affiliates`)
        .then(({ data }) => {
            return data as PublicUserDta[];
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAffiliatesForAdmin = async (uid: string): Promise<PublicUserDta[]> => {
    return axios
        .get(`/user/admin-affiliates/${uid}`)
        .then(({ data }) => {
            return data as PublicUserDta[];
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getNextRank = async (): Promise<any> => {
    return axios
        .get(`/user/next-rank`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserChildAndGrandChild = async (): Promise<DirectActifCount> => {
    return axios
        .get(`/user/childs-grandchids`)
        .then(({ data }) => {
            return data as DirectActifCount;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateProfile = async (payload: any, translate: any): Promise<any> => {
    const formData = new FormData();
    formData.append("last_name", payload.last_name);
    formData.append("first_name", payload.first_name);
    formData.append("email", payload.email);
    payload.entreprise && formData.append("entreprise", payload.entreprise);
    payload.siret && formData.append("siret", payload.siret);
    formData.append("photo", payload.photo);
    formData.append("date_of_birth", payload.date_of_birth);
    formData.append("city", payload.city);
    formData.append("post_code", payload.post_code);
    formData.append("address", payload.address);
    formData.append("phone", payload.phone);
    formData.append("phone_country_code", payload.phone_country_code);
    formData.append("country", payload.country);
    formData.append("currency", payload.currency);
    formData.append("language", payload.language);
    return axios
        .put("/user/update-profile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(({ data }) => {
            const message = translate("Settings", data.code);
            return message;
        })
        .catch((err: any) => {
            return Promise.reject(
                translate("Settings", err.response.data.code),
            );
        });
};

const updateUserProfileByAdmin = async (
    payload: any,
    translate: any,
): Promise<any> => {
    const formData = new FormData();
    formData.append("last_name", payload.last_name);
    formData.append("first_name", payload.first_name);
    formData.append("email", payload.email);
    payload.entreprise && formData.append("entreprise", payload.entreprise);
    payload.siret && formData.append("siret", payload.siret);
    formData.append("photo", payload.photo);
    formData.append("date_of_birth", payload.date_of_birth);
    formData.append("city", payload.city);
    formData.append("post_code", payload.post_code);
    formData.append("address", payload.address);
    formData.append("phone", payload.phone);
    formData.append("phone_country_code", payload.phone_country_code);
    formData.append("country", payload.country);
    formData.append("uid", payload.uid);
    return axios
        .put("/user/update-user-profile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(({ data }) => {
            const message = translate("Settings", data.code);
            return message;
        })
        .catch((err: any) => {
            return Promise.reject(
                translate("Settings", err.response.data.code),
            );
        });
};

const updateChildProfile = async (
    payload: any,
    translate: any,
): Promise<any> => {
    const formData = new FormData();
    formData.append("email", payload.email);
    payload.entreprise && formData.append("entreprise", payload.entreprise);
    payload.siret && formData.append("siret", payload.siret);
    formData.append("photo", payload.photo);
    formData.append("city", payload.city);
    formData.append("post_code", payload.post_code);
    formData.append("address", payload.address);
    formData.append("phone", payload.phone);
    formData.append("phone_country_code", payload.phone_country_code);
    formData.append("country", payload.country);
    formData.append("uid", payload.uid);
    return axios
        .put("/user/update-user-18-profile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(({ data }) => {
            const message = translate("Settings", data.code);
            return message;
        })
        .catch((err: any) => {
            return Promise.reject(
                translate("Settings", err.response.data.code),
            );
        });
};
const updateUserCrypto = async (
    payload: IuserCryptoSetting,
    translate: any,
): Promise<any> => {
    return axios
        .put("/user/update-user-crypto", { ...payload })
        .then(({ data }) => {
            const message = translate("Settings", data.code);
            return message;
        })
        .catch((err: any) => {
            return Promise.reject(
                translate("Settings", err.response.data.code),
            );
        });
};

const updateEmail = async (payload: any): Promise<any> => {
    const formData = new FormData();
    formData.append("email", payload.email);
    return axios
        .put("/user/edit-user-email", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateAutoReinvest = async (): Promise<any> => {
    return axios
        .get(`/user/reinvest-auto`)
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const resetKyc = async (id: string) => {
    return axios
        .delete(`/user/kyc-reset/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const hideZeroAmount = async (): Promise<any> => {
    return axios
        .get(`/user/hide-zero-amount`)
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const hideInactiveEarning = async (): Promise<any> => {
    return axios
        .get(`/user/hide-inactive-earning`)
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getNoParrain = async (): Promise<any> => {
    return axios
        .get(`/user/no-parrain`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const searchRoleUser = async (search: string): Promise<Array<PartenarUser>> => {
    return axios
        .get(`/user/search-all-role-user`, { params: { search } })
        .then(({ data }) => {
            const users: PartenarUser[] = data.map((item: any) =>
                mapPartenar(item),
            );
            return users;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const formatKycStatus = (status: string) => {
    let result = "Non Vérifié";
    switch (status) {
        case "verified":
            result = "Vérifié N1";
            break;
        case "half_verified":
            result = "Vérifié N2";
            break;
        default:
            result = "Non Vérifié";
            break;
    }
    return result;
};

const updateEmailByAdmin = async (payload: IEditProfil): Promise<any> => {
    return axios
        .put("/user/edit-user-email-by-admin", { ...payload })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const addAffiliate = async (payload: IAddAffiliation): Promise<any> => {
    return axios
        .put("/user/edit-user-parrain", { ...payload })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteAffiliate = async (uid: string): Promise<any> => {
    return axios
        .put(`/user/delete-user-parrain/${uid}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getInformationUserByUid = async (uid: string) => {
    try {
        const result = await axios.get(`/user/information/${uid}`);
        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const addParentUserByUid = async (data: any) => {
    return axios
        .put(`/user/add-parent`, data)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const isUserAddedParent = async (data: any) => {
    return axios
        .put(`/user/get-user-parent`, data)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const reset2Fa = async (uid: string): Promise<any> => {
    return axios
        .put(`/user/reset-2fa/${uid}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getNoJoin = async (): Promise<any> => {
    return axios
        .get(`/user/no-join`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getJoinParents = async (): Promise<Array<PartenarUser>> => {
    return axios
        .get(`/user/join-parents`)
        .then(({ data }) => {
            const users: PartenarUser[] = data.map((item: any) =>
                mapPartenar(item),
            );
            return users;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getJoinParentsAdmin = async (
    user_uid: string,
): Promise<Array<PartenarUser>> => {
    return axios
        .get(`/user/join-parents-admin/${user_uid}`)
        .then(({ data }) => {
            const users: PartenarUser[] = data.map((item: any) =>
                mapPartenar(item),
            );
            return users;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getNoPerso = async (search: string): Promise<any> => {
    return axios
        .get(`/user/no-perso`, { params: { search } })
        .then(({ data }) => {
            const users: PartenarUser[] = data.map((item: any) =>
                mapPartenar(item),
            );
            return users;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const savePerso = async (perso_uid: string): Promise<any> => {
    return axios
        .put(`/user/add-perso/${perso_uid}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllPerso = async (): Promise<Array<User>> => {
    return axios
        .get(`/user/all-perso`)
        .then(({ data }) => {
            const users: User[] = data.map((item: any) => map(item));
            return users;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deletePersoById = async (uid: string) => {
    return axios
        .delete(`/user/perso/${uid}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateUserSetting = async (
    payload: IUserSetting,
    translate: any,
): Promise<any> => {
    return axios
        .post("/user/user-setting", payload)
        .then(() => {
            return Promise.resolve(
                translate("USER_SETTING", "Setup_Successfully"),
            );
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateUserSetting2 = async (payload: IUserSetting): Promise<any> => {
    return axios
        .post("/user/user-setting", payload)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateWidgetSetting = async (
    payload: Omit<WidgetCard, "content">[],
): Promise<any> => {
    const data: WidgetSetting = {
        setting: JSON.stringify(payload),
    };

    return axios
        .post("/user/widget-setting", data)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateThemeSetting = async (
    payload: ThemeSetting,
    translate: any,
): Promise<any> => {
    return axios
        .post("/user/theme-setting", payload)
        .then(() => {
            return true;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const UserService = {
    getAll,
    deleteById,
    getTopFive,
    forgotPassword,
    resetPassword,
    getAffiliates,
    getNextRank,
    getUserChildAndGrandChild,
    updateProfile,
    getAllTUsersFilter,
    updateEmail,
    updateAutoReinvest,
    resetKyc,
    hideZeroAmount,
    formatKycStatus,
    updateEmailByAdmin,
    hideInactiveEarning,
    getNoParrain,
    addAffiliate,
    getAffiliatesForAdmin,
    deleteAffiliate,
    getPartenar,
    getAllWithFidelity,
    updateUserProfileByAdmin,
    getAllTAccount18Filter,
    updateUserCrypto,
    getInformationUserByUid,
    addParentUserByUid,
    isUserAddedParent,
    reset2Fa,
    searchRoleUser,
    getNoJoin,
    getJoinParents,
    getJoinParentsAdmin,
    getNoPerso,
    savePerso,
    getAllPerso,
    deletePersoById,
    updateUserSetting,
    updateUserSetting2,
    getPersonnalAccount,
    updateChildProfile,
    updateWidgetSetting,
    updateThemeSetting,
};

export default UserService;
