import PinInput from "react-pin-input";
import UserCard from "../../../Models/UserCard";
import translator from "../../Extra/Translation/Translate";
import React, { useState } from "react";
import { ModalContext } from "../../Extra/Modal/ModalContext";
import OrderCardService, {
    CodeValidate,
} from "../../../Services/OrderCard/OrderCardService";
import alert_message from "../../../Services/Alert/AlertMessage";
import { useCardContext } from "../../../App/Provider/CardProvider";
import { useFlyoutContext } from "../../../App/Provider/FlyOutProvider";

const ActiveOrderContent = ({
    selectedCard,
    hideTitle = false,
}: {
    selectedCard?: UserCard;
    hideTitle?: boolean;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    const { activeComponent, setActiveComponent, title } = useFlyoutContext();
    const [processing, setProcessing] = useState<boolean>(false);
    const [code, setCode] = useState<string>("");

    const { refreshUserCards } = useCardContext();

    const ActiveCardRequest = () => {
        let message1 = translate("ORDER_CARD_REAUEST", "Activation_In_Process");
        let message2 = translate(
            "ORDER_CARD_REAUEST",
            "Activated_With_Success",
        );
        if (!!selectedCard?.order?.is_active) {
            message1 = translate("ORDER_CARD_REAUEST", "Disabled_With_Success");
            message2 = translate("ORDER_CARD_REAUEST", "Disabled_With_Success");
        }
        if (selectedCard?.order?.id) {
            setProcessing(true);
            const data: CodeValidate = {
                code: code,
            };
            try {
                const res = OrderCardService.activeCardRequest(
                    selectedCard?.order?.id,
                    data,
                );
                setProcessing(false);
                alert_message
                    .promise(message1, res, message2)
                    .then((data) => {
                        handleModal();
                        refreshUserCards();
                        setActiveComponent(undefined);
                    })
                    .finally(() => {
                        setProcessing(false);
                    });
            } catch (error) {}
        }
    };
    return (
        <>
            {!hideTitle && (
                <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                    <div className="flex justify-center items-center p-2 text-lg font-bold">
                        {!!selectedCard?.order?.is_active
                            ? translate("ORDER_CARD_REAUEST", "Bloc_Card_Title")
                            : translate(
                                  "ORDER_CARD_REAUEST",
                                  "Activate_Card_Title",
                              )}
                    </div>
                </div>
            )}
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 lg:col-span-12 ">
                    <div className="mt-10 justify-center items-center p-2 text-center text-base">
                        {translate("ORDER_CARD_REAUEST", "Activate_Card")}
                    </div>
                    <div className="intro-y">
                        <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 ">
                            <div className="flex justify-center items-center p-2 text-lg font-bold">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "left",
                                    }}
                                >
                                    <div className="flex justify-center items w-full ">
                                        <PinInput
                                            length={4}
                                            initialValue={code}
                                            onChange={(value) => {
                                                setCode(value);
                                            }}
                                            type="numeric"
                                            inputMode="number"
                                            style={{ padding: "10px" }}
                                            inputStyle={{
                                                borderColor: "#1b253b",
                                                borderRadius: "10px",
                                            }}
                                            inputFocusStyle={{
                                                borderColor: "#053B3B",
                                            }}
                                            autoSelect={true}
                                            regexCriteria={
                                                /^[ A-Za-z0-9_@./#&+-]*$/
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end items md:right ">
                                <button
                                    type="button"
                                    className="btn btn-perfect mt-2 mr-2"
                                    onClick={() => ActiveCardRequest()}
                                    disabled={code.length !== 4 || processing}
                                >
                                    {!!selectedCard?.order?.is_active
                                        ? translate("WALLET", "BLOC")
                                        : translate(
                                              "ORDER_CARD_REAUEST",
                                              "Activate",
                                          )}
                                </button>
                                <button
                                    type="button"
                                    className="btn  mt-2"
                                    onClick={() => {
                                        handleModal();
                                        setActiveComponent(undefined);
                                    }}
                                    disabled={processing}
                                >
                                    {translate("Button", "Cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActiveOrderContent;
