import React from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./ModalContext";

const Modal = ({ freeze }: { freeze?: boolean }) => {
    const { modalContent, handleModal, modal, animation } =
        React.useContext(ModalContext);

    if (typeof document === "undefined") return null;

    if (modal) {
        document?.querySelector("#app-modal")?.classList.remove("sm-hidden");
        return ReactDOM.createPortal(
            <>
                <div
                    className="bg-[rgba(0,0,0,0.7)] h-full w-full top-0 z-[102] fixed flex items-center justify-center cursor-pointer"
                    // className="bg-red-500 h-screen w-full z-[50] fixed flex items-center justify-center cursor-pointer"
                    role="button"
                    onClick={() => !!freeze === false && handleModal()}
                ></div>
                <div className="w-modal top-[110px] z-[103] fixed sm-pos-modal">
                    <div
                        className={`block box relative rounded-md  px-5 pt-10 pb-5 sm-con-modal top-[-50px] w-full h-full ${animation}`}
                    >
                        <div className="flex items-start justify-end absolute top-1.5 right-2.5">
                            {!!freeze === false && (
                                <button
                                    className=""
                                    onClick={() => handleModal()}
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            )}
                        </div>
                        <div>{modalContent}</div>
                    </div>
                </div>
            </>,
            document.querySelector("#app-modal") as HTMLElement,
        );
    } else {
        document?.querySelector("#app-modal")?.classList.add("sm-hidden");
        return null;
    }
};

export default Modal;
