import { useEffect } from "react";
import translator from "../Extra/Translation/Translate";
import loadable from "@loadable/component";
import CardList, { CardHeaderAction, OrderVirtualCard } from "./CardList";
import { useAppProvider } from "../../App/Provider/AppProvider";
import { ModalProvider } from "../Extra/Modal/ModalContext";
import { useCardContext } from "../../App/Provider/CardProvider";
import LastTransactionTable from "../Transactions/LastTransanctionTable";
import { TransactionDetailProvider } from "../../App/Provider/TransactionProvider";
import { AddAccountsProvider } from "../../App/Provider/AddAccountsProvider";
import BtnFlyOutAddCardVirtual from "./CardVirtualMob/btnFlyOutAddCardVirtual";
import CreateCardVirtualFlyOut from "./CardVirtualMob/createCardVirtual";
import { WalletHistoryType } from "../../Models/Transaction";
import FlyOutActiveCard from "./activeCard/FlyOutActiveCard";

const CardDetail = loadable(() => import("./CardDetail"));

const MyCard = () => {
    const { translate } = translator();
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const { selectedCard, setSelectedCard } = useCardContext();

    useEffect(() => {
        finalizeProcessing();
        setSelectedCard(null);
    }, []);
    return (
        <TransactionDetailProvider>
            <AddAccountsProvider>
                <div className=" block md:flex relative">
                    <div className="w-full px-[22px]  md:px-[37px] md:pt-[60px] md:pb-[22px]">
                        <div className="grid grid-cols-12  md:gap-4 gap-2">
                            <div className=" intro-y mt-5 items-center md:pb-5 col-span-12">
                                <h1 className="mr-auto text-2xl font-medium !capitalize">
                                    {translate("Cards", "Cards")}
                                </h1>
                            </div>
                            <div className="col-span-12 hidden md:block">
                                <CardHeaderAction />
                            </div>
                            <div
                                className={`${
                                    selectedCard && ""
                                } col-span-12 rounded-lg sm:border-none md:box md:bg-slate-200 mx-0.4 py-0 px-0 mb-0 md:!mb-2 md:dark-bg-slate-300 dark:bg-opacity-50 dark:!rounded-lg  `}
                            >
                                <CardList></CardList>
                            </div>

                            <div className="col-span-12 hidden md:block">
                                <LastTransactionTable
                                    type={WalletHistoryType.CARD_TRANSANCTON}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`${!selectedCard && "hidden"}  md:flex  `}>
                        <div className="md:h-full">
                            <CardDetail></CardDetail>
                        </div>
                        <div className="px-[15px] mb-[22px] md:hidden">
                            <LastTransactionTable
                                type={WalletHistoryType.CARD_TRANSANCTON}
                            />
                        </div>
                    </div>
                    <div className="w-full  md:hidden"></div>
                    {!selectedCard && (
                        <div className="flex justify-center bottom-24 fixed w-full md:hidden">
                            <BtnFlyOutAddCardVirtual />
                        </div>
                    )}
                </div>
                <CreateCardVirtualFlyOut></CreateCardVirtualFlyOut>
                <FlyOutActiveCard></FlyOutActiveCard>
            </AddAccountsProvider>
        </TransactionDetailProvider>
    );
};

export default MyCard;
