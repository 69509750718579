import React, { useEffect, useState } from "react";
import UtilsService, { formatAmount } from "../../../Services/UtilsService";
import translator from "../Translation/Translate";
import DataTableFilter from "./filter/DataTableFilter";
import DataTablePagination from "./pagination/DataTablePagination";
import {
    COMMISSION_TRANSACTIONS,
    OUT_TRANSACTIONS,
    WalletHistoryStatus,
    WalletHistoryType,
} from "../../../Models/Transaction";
import { useTransactionDetail } from "../../../App/Provider/TransactionProvider";
import { useFlyoutContext } from "../../../App/Provider/FlyOutProvider";

export interface DataResponsiveTableColums {
    index: string;
    title: string;
    content: (data: any) => React.ReactNode;
    filter?: boolean;
    className?: string;
}

interface DataTableProps {
    columns: DataResponsiveTableColums[];
    data: any[];
    pageLength?: number;
    className?: string;
    tableButton?: React.ReactNode;
    onSearchInputChange?: (findText: string) => void;
    handlePerRowsChange?: (page: number) => void;
    handlePaginationChange?: (page: number) => void;
    searchByDate?: (startDate?: string, endDate?: string) => void;
    nbPage: number;
    exportCSV?: boolean;
    last?: boolean;
}
interface MobileTransactionData {
    date: string;
    transactions: any;
}

const convertDataToMobileTransactions = (
    data: any,
): MobileTransactionData[] => {
    const transactions: MobileTransactionData[] = [];

    const date_now = new Date();
    const today = new Date();
    const yesterdayD = date_now.setDate(date_now.getDate() - 1);
    const yesterday = new Date(yesterdayD);
    const today_formated = UtilsService.getBeautifulFormatedDateFr(today);
    const yesterday_formated =
        UtilsService.getBeautifulFormatedDateFr(yesterday);

    for (const item of data) {
        const date = new Date(item.date);

        let date_formated = UtilsService.getBeautifulFormatedDateFr(date);
        if (date_formated === yesterday_formated) {
            date_formated = "yesterday";
        } else if (date_formated === today_formated) {
            date_formated = "today";
        }
        const index = transactions.findIndex(
            (element) => element.date === date_formated,
        );
        if (index !== -1) {
            transactions[index].transactions.push(item);
        } else {
            transactions.push({
                date: date_formated,
                transactions: [item],
            });
        }
    }
    return transactions;
};

const ContentTableItem = ({
    transaction,
    last,
}: {
    transaction: any;
    last: boolean;
}) => {
    const { translate } = translator();
    const { setFlyoutOpen } = useFlyoutContext();
    const { setTransaction } = useTransactionDetail();
    const includedValues = OUT_TRANSACTIONS;

    const credit = !includedValues.includes(transaction.type);

    const amount = formatAmount(
        transaction.amount,
        transaction.user_wallet.type === "crypto"
            ? 8
            : COMMISSION_TRANSACTIONS.includes(transaction.type)
              ? 5
              : 2,
    );

    const txt = `${credit ? "+ " : "- "} ${amount}`;
    let color = "";
    switch (transaction.status) {
        case "initialized":
        case "pending":
            color = "bg-yellow-500";
            break;
        case "completed":
            color = "bg-green-500";
            break;
        case "cancelled":
            color = "bg-red-500";
            break;
        default:
            break;
    }
    let class_name = !includedValues.includes(transaction.type)
        ? "green"
        : "red";

    if (transaction.status === WalletHistoryStatus.CANCELLED) {
        class_name = "red";
    } else if (
        transaction.status === WalletHistoryStatus.PENDING ||
        transaction.status === WalletHistoryStatus.INITIALIZED ||
        transaction.type === WalletHistoryType.STAKING
    ) {
        class_name = "yellow";
    }

    const abbr =
        transaction.user_wallet.type === "fiat"
            ? transaction.user_wallet.currency
            : transaction.user_wallet.abbreviation;
    return (
        <div
            className="box p-1 my-2 relative dark:!border dark:!border-slate-700 dark:!rounded-md"
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                if (last) return;
                setTransaction(transaction);
                setFlyoutOpen(true);
            }}
        >
            <div
                className={`absolute top-2 left-[5px] ${color} w-2 h-2 rounded-full`}
            ></div>
            <div className="flex justify-between mb-2  mt-3 px-2">
                <div className="font-bold text-xs">
                    {translate("TransactionType", transaction.type)}
                </div>
                <div className={`text-${class_name}-600 text-xs`}>
                    {txt + " " + abbr}
                </div>
            </div>
            <div className="flex justify-between px-2">
                <div className="text-xs text-slate-500 block w-full h-[35px]">
                    {transaction.getTransactionDetails(translate)}
                </div>
                <div className="w-4/12 text-right text-xs hidden">
                    +0.00 USDT
                </div>
            </div>
        </div>
    );
};
const ContentTable = ({ data, last }: { data: any; last: boolean }) => {
    const [nextTransact, setNextTransact] = useState<boolean>(false);
    const { translate } = translator();

    const transactions = convertDataToMobileTransactions(data);

    return (
        <div className="intro-y col-span-12 mt-2 md-flex flex-wrap justify-between items-center sm:flex-nowrap justify-center-mobile !z-10">
            {data.length === 0 && (
                <div className="bg-white-transaction text-center w-full rounded-lg simple-hidden">
                    {translate("DATATABLE_LABEL", "Nothing_To_Show")}
                </div>
            )}
            {transactions.map((item: any, i: number) => {
                return (
                    <div
                        key={`md-${i}`}
                        className="bg-white-transaction px-2 py-1 rounded-lg w-full mb-2 simple-hidden"
                    >
                        <div className="text-slate-500 w-full">
                            {translate("TransactionType", item.date)}
                            {item.transactions.map(
                                (transaction: any, index: number) => (
                                    <ContentTableItem
                                        transaction={transaction}
                                        last={last}
                                        key={`content-item-${index}`}
                                    />
                                ),
                            )}
                        </div>
                    </div>
                );
            })}

            <button
                type="button"
                className="btn hidden btn-transparent !border-none float-right underline"
                onClick={() => setNextTransact(true)}
            >
                {nextTransact ? (
                    <>
                        <svg
                            aria-hidden="true"
                            role="status"
                            className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#1C64F2"
                            />
                        </svg>
                        Loading...
                    </>
                ) : (
                    <>{translate("Admin_Dashboard", "View_More")}</>
                )}
            </button>
        </div>
    );
};

const TransactionResponsiveTable = ({
    columns,
    data,
    pageLength,
    tableButton,
    onSearchInputChange,
    searchByDate,
    handlePerRowsChange,
    handlePaginationChange,
    nbPage,
    exportCSV,
    last = false,
    className,
}: DataTableProps) => {
    const [perPage, setPerPage] = useState<number>(
        pageLength ? pageLength : 10,
    );

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentData, setCurrentData] = useState<any[]>(data);

    useEffect(() => {
        setCurrentData(data);
    }, [data]);

    return (
        <>
            {!last && (
                <DataTableFilter
                    currentData={currentData}
                    setPerPage={setPerPage}
                    tableButton={tableButton}
                    perPage={perPage}
                    onSearchInputChange={onSearchInputChange}
                    search={searchByDate}
                    handlePerRowsChange={handlePerRowsChange}
                    exportCSV={exportCSV}
                />
            )}

            {last ? (
                <div className={className}>
                    {" "}
                    <ContentTable data={currentData} last={last} />
                </div>
            ) : (
                <ContentTable data={currentData} last={last} />
            )}

            {!last && (
                <DataTablePagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handlePaginationChange={handlePaginationChange}
                    nbPage={nbPage}
                    className={"hidden sm-block"}
                />
            )}
        </>
    );
};

export default TransactionResponsiveTable;
